import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CASESDETAILS,
  mainGetServices,
  mainPostServices,
  THERAPY,
} from "../shared/services";
import Loader from "./Loader";
import QRCode from "react-qr-code";
import EditParts from "./EditParts";
import SelectParts from "./SelectParts";
import { toast } from "react-toastify";
import HistoryPopup from "./HistoryPopup";
import BackButton from "./BackButton";

function TechnicianDetails() {
  let Auth = useSelector((e) => e.commonData?.auth);

  const navigate = useNavigate();
  const [caseDetails, setCaseDetails] = useState([]);
  const [loader, setLoader] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [arrived, setArrived] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [paid, setPaid] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [start, setStart] = useState(0);
  const [paymentOption, setPaymentOption] = useState("total_due");
  const [paymentMode, setPaymentMode] = useState("cash");
  // const [discountMode, setDiscountMode] = useState(caseDetails?.therapy?.discount)
  const [partstable, setPartstable] = useState(false);
  const [customPrice, setCustomPrice] = useState("");
  const [historyPopup, setHistoryPopup] = useState(false);

  const [infoPopup, setInfoPopup] = useState(false);
  const [addPartPopup, setAddPartPopup] = useState(false);
  const [selectPartPopup, setSelectPartPopup] = useState(false);

  const dispatch = useDispatch();

  let caseData = useSelector((e) => e.commonData.caseDetails);
  useEffect(() => {
    setLoader(true);
    if (Auth) {
      getCaseDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Auth]);

  useEffect(() => {
    if (caseData?.therapy?.start_therapy === 1) {
      navigate(`/technician/${caseData?.id}/therapy`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseDetails]);

  const getCaseDetails = async () => {
    try {
      const { response_data } = await mainGetServices(
        `${CASESDETAILS}/${window.location.pathname.split("/")[2]}`
      );
      if (response_data) {
        setCaseDetails(response_data);
        dispatch({ type: "CASE_INFO", response_data });
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getThearpahyDetails = async (data) => {
    try {
      await mainPostServices(
        `${THERAPY}/${window.location.pathname.split("/")[2]}`,
        data
      );
      setPartstable(false);
      getCaseDetails();
    } catch (error) {
      console.log(error);
    }
  };

  const getArrvial = async () => {
    setArrived(1);
    let data = { arrived: 1 };
    setLoader(true);
    getThearpahyDetails(data);
  };

  const getPaid = async (event) => {
    event.preventDefault();
    setPaid(1);
    let data = { paid: 1 };
    if (paymentOption === "custom") {
      data = {
        paid: 1,
        paid_amount_type: paymentOption,
        paid_amount: +customPrice,
        payment_mode: paymentMode,
      };
    } else {
      data = {
        paid: 1,
        paid_amount_type: paymentOption,
        payment_mode: paymentMode,
      };
    }
    setLoader(true);
    getThearpahyDetails(data);
  };

  // const handleDiscount = async (e) => {
  //     setLoader(true)
  //     setDiscountMode(e)
  //     let data = { discount: +e }
  //     try {
  //         await mainPostServices(`${THERAPY}/${window.location.pathname.split('/')[2]}`, data);
  //         getCaseDetails()
  //         setLoader(false)
  //     } catch (error) {
  //         console.log(error);
  //     }
  // }

  const getStartTherapy = async (id) => {
    setStart(1);
    let data = { start_therapy: 1 };
    setLoader(true);
    try {
      const { response_data } = await mainPostServices(
        `${THERAPY}/${window.location.pathname.split("/")[2]}`,
        data
      );
      if (response_data?.therapy?.start_therapy === 1) {
        navigate(`/technician/${caseDetails.id}/therapy`);
      }
      getCaseDetails();
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  const calculateGst = (price) => {
    let result = price * 0.18;
    return result;
  };

  const handleDelete = async (id) => {
    setLoader(true);
    let data = {
      type: "remove",
      part_session_id: id,
    };
    try {
      const { response_type } = await mainPostServices(
        `${THERAPY}/${window.location.pathname.split("/")[2]}/update_part`,
        data
      );
      if (response_type === "success") {
        toast.success(response_type);
        getCaseDetails();
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div className="container" style={{ backgroundColor: "#fff" }}>
        <div className="details">
          <header>
            <div className="header_left">
              <BackButton url={"/technician/calender"} />
            </div>
            <div className="header_center">
              <h1> Technician app </h1>
            </div>
            <div className="header_right">
              <button onClick={() => setHistoryPopup(!historyPopup)}>
                <svg
                  width="44"
                  height="38"
                  viewBox="0 0 44 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.0833 0.25C20.1105 0.25 15.3414 2.22544 11.8251 5.74175C8.30878 9.25805 6.33334 14.0272 6.33334 19H0.0833435L8.18751 27.1042L8.33334 27.3958L16.75 19H10.5C10.5 10.9375 17.0208 4.41667 25.0833 4.41667C33.1458 4.41667 39.6667 10.9375 39.6667 19C39.6667 27.0625 33.1458 33.5833 25.0833 33.5833C21.0625 33.5833 17.4167 31.9375 14.7917 29.2917L11.8333 32.25C13.5696 33.9959 15.6344 35.3807 17.9085 36.3247C20.1826 37.2687 22.6211 37.7531 25.0833 37.75C30.0562 37.75 34.8253 35.7746 38.3416 32.2582C41.8579 28.7419 43.8333 23.9728 43.8333 19C43.8333 14.0272 41.8579 9.25805 38.3416 5.74175C34.8253 2.22544 30.0562 0.25 25.0833 0.25ZM23 10.6667V21.0833L31.9167 26.375L33.4167 23.8542L26.125 19.5208V10.6667H23Z"
                    fill="white"
                  />
                </svg>
              </button>
              <button onClick={() => navigate(`/technician/dashboard`)}>
                <svg
                  width="42"
                  height="36"
                  viewBox="0 0 42 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 5.85417L31.4167 15.2292V31.5H27.25V19H14.75V31.5H10.5833V15.2292L21 5.85417ZM21 0.25L0.166656 19H6.41666V35.6667H18.9167V23.1667H23.0833V35.6667H35.5833V19H41.8333L21 0.25Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </header>

          <div className="user_deatil">
            <h2>
              <span onClick={() => setInfoPopup(!infoPopup)}>
                {" "}
                {caseDetails?.customer?.first_name}{" "}
              </span>
            </h2>
            <span className="balance"> {caseDetails?.customer?.id} </span>
          </div>
        </div>

        <div className="dashboard details_content">
          <div className="dashboard_wrp">
            <div className="Advice other_details ">
              <div className="concern_wrp ">
                <h2> Arrive Customer </h2>
              </div>

              <div className="item_wrp">
                <p className="blue_btn" onClick={() => getArrvial()}>
                  {" "}
                  {caseDetails?.therapy?.arrived === 1
                    ? " Arrived "
                    : "Arrival"}
                </p>
                <div
                  className="parts_details"
                  onClick={() => setPartstable(!partstable)}
                >
                  part and sessions info
                </div>
                {/* <span className={`timer_btn ${caseDetails?.therapy?.arrived === null ? 'disable' : ''} `} onClick={() => getPaid()}> {caseDetails?.therapy?.paid === 1 ? "Paid" : "Pending"} </span> */}
              </div>
            </div>

            <div className="Advice other_details ">
              <div className="concern_wrp ">
                <h2> Start Therapy </h2>
              </div>
              <div className="item_wrp">
                <div
                  className={`start_btn ${
                    caseDetails?.therapy?.paid === null ||
                    caseDetails?.therapy?.paid === 0
                      ? "disable"
                      : ""
                  } `}
                >
                  <span onClick={() => getStartTherapy()}>Start</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {partstable && (
        <div className="popup parts_table">
          <div className="popup_title">
            <h2> Part And Sessions Info </h2>
          </div>
          <div className="popup_content">
            <div className="popup_content_wrp">
              <form onSubmit={getPaid}>
                <div className="parts_table_wrp">
                  <div className="parts_table_wrp_table">
                    <table>
                      <thead>
                        <tr>
                          <th> Part </th>
                          <th> Session </th>
                          <th> Amount</th>
                          <th> Minimum Pay </th>
                          <th> Discount </th>
                          {caseDetails.therapy.paid !== 1 && (
                            <th style={{ textAlign: "center" }}> Action</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {caseDetails?.case_active_parts?.length ? (
                          caseDetails?.case_active_parts?.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td> {item.part.name} </td>
                                <td> {item.part_session.session} </td>
                                <td> Rs.{item?.part_session_total_price} </td>
                                <td> Rs.{item?.part_session_minimum_price} </td>
                                <td> {item?.discount}% </td>
                                {caseDetails.therapy.paid !== 1 && (
                                  <td>
                                    {item.completed_sessions === 0 ? (
                                      <span
                                        style={{
                                          border: "none",
                                          backgroundColor: "transparent",
                                          padding: "0",
                                          display: "flex",
                                          cursor: "pointer",
                                          justifyContent: "center",
                                        }}
                                        onClick={() => handleDelete(item.id)}
                                      >
                                        <svg
                                          width="20px"
                                          height="20px"
                                          viewBox="0 0 1024 1024"
                                          class="icon"
                                          version="1.1"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M779.5 1002.7h-535c-64.3 0-116.5-52.3-116.5-116.5V170.7h768v715.5c0 64.2-52.3 116.5-116.5 116.5zM213.3 256v630.1c0 17.2 14 31.2 31.2 31.2h534.9c17.2 0 31.2-14 31.2-31.2V256H213.3z"
                                            fill="#3688FF"
                                          />
                                          <path
                                            d="M917.3 256H106.7C83.1 256 64 236.9 64 213.3s19.1-42.7 42.7-42.7h810.7c23.6 0 42.7 19.1 42.7 42.7S940.9 256 917.3 256zM618.7 128H405.3c-23.6 0-42.7-19.1-42.7-42.7s19.1-42.7 42.7-42.7h213.3c23.6 0 42.7 19.1 42.7 42.7S642.2 128 618.7 128zM405.3 725.3c-23.6 0-42.7-19.1-42.7-42.7v-256c0-23.6 19.1-42.7 42.7-42.7S448 403 448 426.6v256c0 23.6-19.1 42.7-42.7 42.7zM618.7 725.3c-23.6 0-42.7-19.1-42.7-42.7v-256c0-23.6 19.1-42.7 42.7-42.7s42.7 19.1 42.7 42.7v256c-0.1 23.6-19.2 42.7-42.7 42.7z"
                                            fill="#5F6379"
                                          />
                                        </svg>
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                )}
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={4} style={{ textAlign: "center" }}>
                              {" "}
                              No Data Found{" "}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <table style={{ width: "100%" }}>
                    <td>
                      {caseDetails.therapy.paid !== 1 ? (
                        <span
                          className="selectparts"
                          onClick={() => setSelectPartPopup(true)}
                        >
                          {" "}
                          Select Parts{" "}
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {caseDetails.therapy.paid !== 1 ? (
                        <span
                          className="addparts"
                          onClick={() => setAddPartPopup(true)}
                        >
                          {" "}
                          Add More Parts{" "}
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                  </table>
                  <ul>
                    {caseDetails.therapy.paid === 1 ? (
                      <>
                        <li>
                          <label htmlFor="totaldue">
                            {" "}
                            Paid Amount: Rs.{
                              caseDetails?.customer.total_paid
                            }{" "}
                          </label>
                        </li>
                        <li>
                          <label htmlFor="totaldue">
                            {" "}
                            Payment Option: {
                              caseDetails?.payment?.payment_mode
                            }{" "}
                          </label>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          {/* <input id='totalpayment' type="radio" name='payment' value="total_payment" onChange={(e) => setPaymentOption(e.target.value)} /> */}
                          <label htmlFor="totalpayment">
                            {" "}
                            Total Payment : Rs.{
                              caseDetails?.total_tax_payment
                            }{" "}
                          </label>
                        </li>
                        <li>
                          <input
                            id="totaldue"
                            type="radio"
                            name="payment"
                            value="total_due"
                            checked={paymentOption === "total_due"}
                            onChange={(e) => setPaymentOption(e.target.value)}
                          />
                          {/* <label htmlFor='totaldue'> Total Due : Rs.{caseDetails?.total_due + calculateGst(caseDetails?.total_payment)} (Rs.{caseDetails?.total_due}+ Rs.{calculateGst(caseDetails?.total_payment).toFixed(2)}(18%GST))  </label> */}
                          <label htmlFor="totaldue">
                            {" "}
                            Total Due : Rs.{caseDetails?.total_due} (Rs.
                            {caseDetails?.total_due -
                              caseDetails?.total_due_tax}{" "}
                            + {caseDetails?.total_due_tax} (18%GST)){" "}
                          </label>
                        </li>
                        <li>
                          <input
                            id="minimumpayment"
                            type="radio"
                            name="payment"
                            value="minimun_payment"
                            onChange={(e) => setPaymentOption(e.target.value)}
                          />
                          <label htmlFor="minimumpayment">
                            {" "}
                            Minimum Payment : Rs.
                            {caseDetails?.minimun_payment +
                              calculateGst(caseDetails?.minimun_payment)}{" "}
                            (Rs.{caseDetails?.minimun_payment}+ Rs.
                            {calculateGst(caseDetails?.minimun_payment).toFixed(
                              2
                            )}
                            (18%GST)){" "}
                          </label>
                        </li>
                        <li>
                          <input
                            id="custompayment"
                            type="radio"
                            name="payment"
                            value="custom"
                            onChange={(e) => setPaymentOption(e.target.value)}
                          />
                          <label htmlFor="custompayment">
                            {" "}
                            Custom Payment{" "}
                          </label>
                          {paymentOption === "custom" && (
                            <input
                              className="custom"
                              type="number"
                              // min={
                              //   caseDetails?.minimun_payment +
                              //   calculateGst(caseDetails?.minimun_payment)
                              // }
                              min="0"
                              max={caseDetails?.total_due}
                              placeholder="0"
                              step="any"
                              onChange={(e) => setCustomPrice(e.target.value)}
                              required
                            />
                          )}
                        </li>
                      </>
                    )}
                  </ul>
                  {caseDetails.therapy.paid === 1 ? (
                    ""
                  ) : (
                    <>
                      <fieldset>
                        <label> Payment Option: </label>
                        <select
                          value={caseDetails?.therapy?.payment_mode}
                          name="payment_mode"
                          onChange={(e) => setPaymentMode(e.target.value)}
                        >
                          <option value="cash"> Cash</option>
                          <option value="card"> Card</option>
                          <option value="netbanking"> Net Banking</option>
                          <option value="scan"> Scan </option>
                        </select>
                      </fieldset>
                      {/* <fieldset>
                                            <label> Discount Option: </label>
                                            <select value={discountMode} name="discount" onChange={(e) => handleDiscount(e.target.value)}>
                                                <option value="0"> No Discount </option>
                                                <option value="5"> 5% Discount </option>
                                                <option value="10"> 10% Discount </option>
                                                <option value="15"> 15% Discount </option>
                                            </select>
                                        </fieldset> */}
                    </>
                  )}
                </div>
                <div className="parts_table_btn">
                  {caseDetails.therapy.paid === 1 ? (
                    ""
                  ) : (
                    <button
                      className={`pay_btn ${
                        caseDetails?.therapy?.arrived === null ? "disable" : ""
                      } `}
                      type="submit"
                    >
                      {" "}
                      Take Payment
                    </button>
                  )}
                  <span
                    className="close_btn"
                    onClick={() => setPartstable(!partstable)}
                  >
                    {" "}
                    {caseDetails.therapy.paid !== 1 ? "Cancel" : "Close"}{" "}
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {historyPopup && (
        <div className="popup history">
          <HistoryPopup
            setHistoryPopup={setHistoryPopup}
            customerID={caseDetails?.customer_id}
          />
        </div>
      )}

      {infoPopup && (
        <div className="popup infopopup">
          <div className="popup_content ">
            <div className="popup_title">
              <h2> UserInfo </h2>
            </div>
            <div className="popup_content_wrp">
              <ul>
                {console.log(caseDetails.customer)}
                <li>
                  <label> Name: </label>
                  <span>
                    {" "}
                    {caseDetails.customer.first_name}{" "}
                    {caseDetails.customer.middle_name}{" "}
                    {caseDetails.customer.last_name}{" "}
                  </span>
                </li>
                <li>
                  <label> Gender: </label>
                  <span> {caseDetails.customer.gender} </span>
                </li>

                <li>
                  <label> Email: </label>
                  <span> {caseDetails.customer.email} </span>
                </li>

                <li>
                  <label> DOB: </label>
                  <span> {caseDetails.customer?.date_of_birth}</span>
                </li>

                <li>
                  <label> Age: </label>
                  <span> {caseDetails.customer?.age} year </span>
                </li>

                <li>
                  <label> Occupation: </label>
                  <span>
                    {" "}
                    {caseDetails.customer?.occupation
                      ? caseDetails.customer?.occupation
                      : "-"}
                  </span>
                </li>

                <li>
                  <label> Mobile: </label>
                  <span> {caseDetails.customer.mobile} </span>
                </li>

                <li>
                  <label> Alternative Mobile: </label>
                  <span> {caseDetails.customer.alternative_mobile} </span>
                </li>

                <li>
                  <label> Address: </label>
                  <span> {caseDetails.customer.Address} </span>
                </li>

                <li>
                  <label> City: </label>
                  <span> {caseDetails.customer?.city}</span>
                </li>

                <li>
                  <label> State: </label>
                  <span> {caseDetails.customer?.state}</span>
                </li>

                <li>
                  <label> Blood Group: </label>
                  <span>
                    {" "}
                    {caseDetails.customer?.bloodGroup
                      ? caseDetails.customer?.bloodGroup
                      : "-"}
                  </span>
                </li>
                <li>
                  <label> Preferred Language: </label>
                  <span>
                    {" "}
                    {caseDetails.customer?.preferred_language
                      ? caseDetails.customer?.preferred_language
                      : "-"}
                  </span>
                </li>
                <li>
                  <label> Preferred Shift: </label>
                  <span>
                    {" "}
                    {caseDetails.customer?.preferred_shift
                      ? caseDetails.customer?.preferred_shift
                      : "-"}
                  </span>
                </li>
                <li>
                  <label> Location: </label>
                  <span>
                    {" "}
                    {caseDetails.customer?.location
                      ? caseDetails.customer?.location
                      : "-"}
                  </span>
                </li>
                <li>
                  <label> Referral Code: </label>
                  <span>
                    {" "}
                    {caseDetails.customer.referral_code
                      ? caseDetails.customer.referral_code
                      : "-"}{" "}
                  </span>
                </li>
                <li>
                  <label> Referral Link: </label>
                  <div style={{ background: "white", padding: "16px" }}>
                    <QRCode
                      size={100}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={caseDetails.customer.referral_link}
                      viewBox={`0 0 100 100`}
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div className="popup_footer">
              <button
                className="next_button"
                onClick={() => setInfoPopup(!infoPopup)}
              >
                {" "}
                Close{" "}
              </button>
            </div>
          </div>
        </div>
      )}

      {addPartPopup && (
        <EditParts
          caseInfo={caseDetails}
          close={setAddPartPopup}
          getCaseDetails={getCaseDetails}
        />
      )}
      {selectPartPopup && (
        <SelectParts
          caseInfo={caseDetails}
          close={setSelectPartPopup}
          getCaseDetails={getCaseDetails}
        />
      )}
    </>
  );
}

export default TechnicianDetails;
