import React, { useState } from 'react';

const Accordion = ({ data }) => {

    console.log("dayyyyy", data);
    const [isActive, setIsActive] = useState(false);
    const [imgUrl, setImgUrl] = useState('')
    const [openModal, setOpenModal] = useState(false)

    const handleImageClick = (e) => {
        setImgUrl(e.target.src)
        setOpenModal(true)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    return (
        <>
            <div className="accordion-item">
                <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
                    <div>{data?.schedule_date}</div>
                    <div>{isActive ? '-' : '+'}</div>
                </div>
                {isActive &&
                    <div className="accordion-content">
                        <div className='parts_table_wrp'>
                            <div className='parts_table_wrp_table'>
                                <h3>Parts Details:</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th> Part </th>
                                            <th> Session </th>
                                            <th> Amount</th>
                                            <th> Minimum Pay </th>
                                            <th> Discount </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.case_active_parts?.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td> {item.part.name ? item.part.name : '-'} </td>
                                                    <td> {item.part_session.session ? item.part_session.session : '-'} </td>
                                                    <td> {item.part_session.price ? `Rs.${item.part_session.price} ` : '-'} </td>
                                                    <td> {item.part_session?.minimum_price ? `Rs.${item.part_session?.minimum_price}` : '-'} </td>
                                                    <td> {item?.discount}% </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className='parts_table_wrp_table'>
                                <h3>Payment Details:</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            {/* <th> Total Payment </th> */}
                                            <th> Paid Amount </th>
                                            {/* <th> Paid Amount Type</th> */}
                                            <th> Paid Mode</th>
                                            {/* <th> Total Due </th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr >
                                            {/* <td>{data?.total_tax_payment ? `Rs.${data?.total_tax_payment}` : '-'} </td> */}
                                            <td>{data?.therapy?.paid_amount ? `Rs.${data?.therapy?.paid_amount}` : '-'}   </td>
                                            {/* <td> {data?.payment.paid_amount_type} </td> */}
                                            <td> {data?.payment?.payment_mode ? data?.payment?.payment_mode : '-'} </td>
                                            {/* <td>{data?.total_due ? `Rs.${data?.total_due}` : '-'} </td> */}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='parts_table_wrp_table'>
                                <h3>Parameter Details:</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th> Skin Color </th>
                                            <th> Hair Color</th>
                                            <th> Hair Diameter</th>
                                            <th> Fluence (J/cm2) </th>
                                            <th> Pluse Width (ms)</th>
                                            <th> Frequency (Hz) </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr >
                                            <td> {data?.parameter.skin_color ? data?.parameter.skin_color : '-'} </td>
                                            <td> {data?.parameter.hair_color ? data?.parameter.hair_color : '-'} </td>
                                            <td> {data?.parameter.hair_diameter ? data?.parameter.hair_diameter : '-'} </td>
                                            <td> {data?.parameter.fluence ? data?.parameter.fluence : '-'} </td>
                                            <td> {data?.parameter.pluse_width ? data?.parameter.pluse_width : '-'} </td>
                                            <td> {data?.parameter.frequency ? data?.parameter.frequency : '-'} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            {data?.therapy?.before_image?.length ?
                                <div className='parts_table_wrp_table'>
                                    <h3>Before Image:</h3>
                                    <div className='preview_wrp'>
                                        {data?.therapy?.before_image.map((item, i) => {
                                            return (
                                                <div className='perview_img' style={{ marginBottom: "15px" }} key={i}>
                                                    <img src={item} alt='before_image' onClick={(e) => handleImageClick(e)} />
                                                </div>)
                                        })}
                                    </div>
                                </div>
                                :
                                ""
                            }
                            {data?.therapy?.concern_video ?
                                <div className='parts_table_wrp_table'>
                                    <h3>Concern Video:</h3>
                                    <div className='preview_wrp'>
                                        <div className='perview_video' style={{ marginBottom: "15px" }}>
                                            <video src={data?.therapy?.concern_video} id="video" controls></video>
                                        </div>
                                    </div>
                                </div>
                                :
                                ""
                            }

                            {data?.therapy?.machine_image?.length ?
                                <div className='parts_table_wrp_table'>
                                    <h3>Machine Image:</h3>
                                    <div className='preview_wrp'>
                                        {data?.therapy.machine_image.map((item, i) => {
                                            return (
                                                <div className='perview_img' style={{ marginBottom: "15px" }} key={i}>
                                                    <img src={item} alt='machine_image' onClick={(e) => handleImageClick(e)} />
                                                </div>)
                                        })}
                                    </div>
                                </div>
                                :
                                ""
                            }
                        </div>
                    </div>
                }
            </div>

            {console.log("imgUrl", imgUrl)}

            <div id="myModal" class="modal" style={{ display: openModal ? "block" : "none" }}>
                <span class="close" onClick={() => handleClose()}>&times;</span>
                <img src={imgUrl} class="modal-content" alt='img' />
                <div id="caption"></div>
            </div>
        </>
    );
};

export default Accordion;