import React, { useCallback, useReducer } from 'react'
import Details from './Details';
import Parts from './Parts'
import SelectDate from './SelectDate';
import Confirmation from './Confirmation';
import { useEffect } from 'react';

const chReducer = (state, payload) => ({ ...state, ...payload });

function Appointment() {
    const [state, dispatch] = useReducer(chReducer, {
        formData: [],
        step: 1,
        visitType:'Home',
        firstName: '',
        middleName: '',
        lastName: '',
        gender: 'male',
        email: '',
        dob: '',
        age: '',
        occupation: '',
        mobile: '',
        alternativeMobile: '',
        address: '',
        city: '',
        state: '',
        bloodGroup: '',
        referredBy: '',
        preferredLanguage: 'Gujarati',
        location: '',
        shift: 'morning',
        appointmentDate: '',
        loader: false,
        discount:'',
    })

    const ds = useCallback(state => {
        dispatch(state);
    }, []);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const referredBy = urlParams.get('reference')
        ds({ referredBy: referredBy })
        // eslint-disable-next-line
    }, [])


    return (
        <>
            <div className='container'>
                <div className='appointment'>
                    <div className='appointment_wrp'>
                        {state.step === 1 && <Parts ds={ds} state={state} />}
                        {state.step === 2 && <Details ds={ds} state={state} />}
                        {state.step === 3 && <SelectDate ds={ds} state={state} />}
                        {state.step === 4 && <Confirmation ds={ds} state={state} />}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Appointment