import React, { useState } from 'react'
import { INQUIRY, mainPostServices } from '../shared/services';
import Loader from './Loader';
import { toast } from 'react-toastify';


function Confirmation({ ds, state }) {
    const [confirmPopup, setConfirmPopup] = useState(false)
    const [totalPayment, setTotalPayment] = useState(0)
    const [minmumPayment, setMinmumPayment] = useState(0)

    const handleSubmit = async () => {
        try {
            ds({ loader: true })
            let finalData = JSON.parse(JSON.stringify(state))
            delete finalData.formData;
            delete finalData.step;
            let array = []
            state.formData.map((item, i) => {
                return array.push({ part: item.id, session: item.sessions.id })
            })

            const { response_type, response_data } = await mainPostServices(INQUIRY, { ...finalData, part_session: array, session: state.formData[0].sessions.id });
            console.log("response_data", response_data);

            if (response_type) {
                toast.success(response_type);
                ds({ loader: false })
                window.location.reload()
            }
            else {
                toast.error(response_type);
                ds({ loader: false })
            }
        } catch (error) {
            console.log(error);
        }
    }

    const calculateGst = (price) => {
        let result = (price * 0.18);
        return result
    };

    const handleConfirmPopup = () => {
        setConfirmPopup(true)
        let calcTotalPayment = state?.formData.reduce((accumulator, currentValue) => accumulator + currentValue.sessions.price, 0);
        setTotalPayment(calcTotalPayment)
        let calcMinmumPayment = state?.formData.reduce((accumulator, currentValue) => accumulator + currentValue.sessions.minimum_price, 0);
        setMinmumPayment(calcMinmumPayment)
    }





    return (
        <>
            <header>
                {state?.step > 1 && <button onClick={() => ds({ step: 3 })}><svg width="37px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M18.807 23l-6.67-6.67a.466.466 0 0 1 0-.66L18.807 9" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="#39464e"></path></svg></button>}
                <h1>Confirmation Page</h1>
            </header>
            <div className='confirmation'>
                {state.loader && <Loader />}

                <div className='confirmation_wrp'>
                    {state?.formData.length ?
                        <ul>
                            <li>
                                <h2>Selected Parts : </h2>
                                <div className='edit' onClick={() => ds({ step: 1 })}>
                                    <img src='/images/edit.svg' alt='editIcon' />
                                </div>
                            </li>

                            {
                                state?.formData.map((item, i) => {
                                    return (
                                        <li key={i}>
                                            <label> {item.name} : </label>
                                            <span> Rs. {item?.sessions?.price} (<small> {item?.sessions?.session} </small>) </span>
                                        </li>
                                    )
                                })
                            }
                            <li>
                                <label> Visit Type: </label>
                                <span> {state?.formData[0]?.sessions?.visit_type} </span>
                            </li>
                            <li>
                                <label> Appointment Date :</label>
                                <span> {state?.appointmentDate}
                                    {/* {state?.appointmentDate?.toLocaleDateString('en-GB')} */}
                                </span>
                            </li>
                            <li>
                                <label> Appointment shift :</label>
                                <span> {state?.shift}</span>
                            </li>

                            <li style={{ marginTop: "20px" }}> <h2>User Details :</h2></li>

                            <li>
                                <label> Full Name :</label>
                                <span> {state?.firstName} {state?.middleName} {state?.lastName}</span>
                            </li>

                            <li>
                                <label> Gender:</label>
                                <span> {state?.gender}</span>
                            </li>

                            <li>
                                <label> Email :</label>
                                <span> {state?.email}</span>
                            </li>

                            <li>
                                <label> DOB :</label>
                                <span> {state?.dob}</span>
                            </li>

                            <li>
                                <label> Occupation :</label>
                                <span> {state?.occupation ? state?.occupation : '-'}</span>
                            </li>

                            <li>
                                <label> Mobile :</label>
                                <span> {state?.mobile ? state?.mobile : '-'}</span>
                            </li>

                            <li>
                                <label> Alternative Mobile :</label>
                                <span> {state?.alternativeMobile ? state?.alternativeMobile : '-'}</span>
                            </li>

                            <li>
                                <label> Address :</label>
                                <span> {state?.address}</span>
                            </li>
                            <li>
                                <label> City :</label>
                                <span> {state?.city}</span>
                            </li>

                            <li>
                                <label> State :</label>
                                <span> {state?.state}</span>
                            </li>
                            <li>
                                <label> Blood Group :</label>
                                <span> {state?.bloodGroup ? state?.bloodGroup : '-'}</span>
                            </li>
                            <li>
                                <label> Referred By :</label>
                                <span> {state?.referredBy ? state?.referredBy : '-'}</span>
                            </li>
                            <li>
                                <label> Preferred Language :</label>
                                <span> {state?.preferredLanguage ? state?.preferredLanguage : '-'}</span>
                            </li>
                            <li>
                                <label> Location :</label>
                                <span> {state?.location ? state?.location : '-'}</span>
                            </li>
                        </ul>
                        : <p style={{ textAlign: "center", width: "100%" }}> data not found </p>

                    }
                    {/* <p>(<b>Note:</b> We will connect you soon about your appointment )</p> */}
                </div>

                <button className='next_button' onClick={() => handleConfirmPopup()} > Next </button>
            </div>

            {confirmPopup && <div className='popup confirmopup parts_table'>

                <div className='popup_content'>
                    <div className='popup_title'>
                        <h2> You Are Going To Pay </h2>
                    </div>
                    <div className='popup_content_wrp'>
                        <div className='parts_table_wrp'>
                            <div className='parts_table_wrp_table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th> Part </th>
                                            <th> Session </th>
                                            <th> Amount</th>
                                            <th> Minimum Pay </th>
                                        </tr>
                                    </thead>
                                    {console.log(state?.formData)}
                                    <tbody>
                                        {state?.formData.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td> {item.name} </td>
                                                    <td> {item?.sessions?.session} </td>
                                                    <td> Rs. {item?.sessions?.price} </td>
                                                    <td> Rs.{item.sessions?.minimum_price} </td>
                                                </tr>
                                            )
                                        })
                                        }

                                    </tbody>
                                </table>
                            </div>
                            <small> <b>Note:</b> 18%GST excluded in Total Amount</small>
                            <div style={{ marginTop: "10px" }}>
                                <small>
                                    <b>Total Payment :</b> Rs.{totalPayment + calculateGst(totalPayment)} (Rs.{totalPayment} + Rs.{calculateGst(totalPayment)}(18%GST))<br />
                                    <b>Minmum Payment :</b> Rs.{minmumPayment + calculateGst(minmumPayment)} ( Rs.{minmumPayment} + Rs.{calculateGst(minmumPayment)}(18%GST))
                                </small>
                            </div>
                        </div>
                        <div className='parts_table_btn'>
                            <span className={`pay_btn`} onClick={() => handleSubmit()}> Confirm </span>
                            <span className="close_btn" onClick={() => setConfirmPopup(!confirmPopup)}> Cancel </span>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    )
}

export default Confirmation