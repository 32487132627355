import ACTION_TYPE from "../action/ActionsType";

const initialState = {
  userData: {},
  auth: false,
  caseDetails: {}
};


const commonData = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPE.USER_INFO:
      return Object.assign({}, state, { userData: action.response_data, auth: true });
    case ACTION_TYPE.CASE_INFO:
      return Object.assign({}, state, { caseDetails: action.response_data});
    default:
      return state;
  }
};
export default commonData;
