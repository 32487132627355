import React, { useEffect } from "react";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigationType,
  matchRoutes,
  createRoutesFromChildren,
} from "react-router-dom";
import "./App.css";
import Appointment from "./component/Appointment";
import Login from "./component/Login";
import Calender from "./component/Calender";
import PrivateRoute from "./component/PrivateRoute/PrivateRoute";
import TechnicialApp from "./component/TechnicialApp";
import TechnicianDetails from "./component/TechnicianDetails";
import Dashboard from "./component/Dashboard";
import * as Sentry from "@sentry/react";
import { AUTH, mainGetServices, setToken } from "./shared/services";
import { useDispatch } from "react-redux";

Sentry.init({
  dsn: "https://06bcc0815eb44adfa02d65ef3b99e20b@o4504956340273152.ingest.sentry.io/4504956342042624",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  tracesSampleRate: 1.0,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      window.location.pathname !== "inquiry" &&
      localStorage.getItem("token")
    ) {
      setToken(localStorage.getItem("token"));
      setTimeout(() => {
        getAuth();
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAuth = async () => {
    try {
      const { response_data } = await mainGetServices(AUTH);
      if (response_data) {
        dispatch({ type: "USER_INFO", response_data });
        setToken(localStorage.getItem("token"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SentryRoutes>
      <Route exact path="/inquiry" element={<Appointment />}></Route>
      <Route exact path="/technician" element={<Login />} />
      <Route exact path="/technician" element={<PrivateRoute />}>
        <Route
          exact
          path="/technician/dashboard"
          element={<Dashboard />}
        ></Route>
        <Route exact path="/technician/calender" element={<Calender />}></Route>
        <Route
          exact
          path="/technician/:id/detail"
          element={<TechnicianDetails />}
        ></Route>
        <Route
          exact
          path="/technician/:id/therapy"
          element={<TechnicialApp />}
        ></Route>
      </Route>
      <Route path="*" element={<Navigate replace to="/inquiry" />} />
    </SentryRoutes>
  );
}

export default App;
