import React, { useEffect, useState } from 'react'
import { useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LOGIN, mainPostServices, setToken } from '../shared/services';
import Loader from './Loader';
import { toast } from 'react-toastify';

function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const [loader, setLoader] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem('token')) {
            navigate('/technician/dashboard')
        }
        // eslint-disable-next-line
    }, [])

    const handleSubmit = async (event) => {
        setLoader(true)
        event.preventDefault();
        try {
            const { response_data, response_type, response } = await mainPostServices(LOGIN, { email: email, password: password });
            console.log(response);
            let result = response?.data
            if (result !== undefined && response?.status !== 200 && response.data.response_type !== "success") {
                setLoader(false)
                toast.error(result.response_data.email[0]);
            }
            else {
                toast.success(response_type);
                dispatch({ type: "USER_INFO", response_data });
                localStorage.setItem("token", response_data.token);
                setToken(response_data.token);
                setLoader(false)
                navigate('/technician/dashboard')
            }
        } catch (error) {
            console.log(error);
        }
    }




    return (
        <>
            {loader && <Loader />}
            <div className='container'>
                <div className='login'>
                    <div className='login_wrp'>
                        <div className='login_header'>
                            <h2>
                                Hey , <br />
                                Login Now
                            </h2>
                        </div>
                    </div>
                    <form onSubmit={(event) => handleSubmit(event)}>
                        <div className='username'>
                            <img src='/images/email.svg' alt='email' />
                            <input type="email" placeholder="Email" autoComplete="off" onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className='password'>
                            <img src='/images/lock.svg' alt='lock' />
                            <input type={showPassword ? "text" : "password"} placeholder="Password" autoComplete="off" onChange={(e) => setPassword(e.target.value)} />
                            <span onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <img src='/images/show.png' alt='lock' /> : <img src='/images/hide.png' alt='lock' />}
                            </span>
                        </div>
                        <button>login</button>
                    </form>
                </div>
            </div>
        </>

    )
}

export default Login