import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CASESDETAILS,
  mainGetServices,
  mainPostServices,
  THERAPY,
} from "../shared/services";
import Loader from "./Loader";
import HistoryPopup from "./HistoryPopup";
import { toast } from "react-toastify";
import AWS from "aws-sdk";
import BackButton from "./BackButton";

const S3_BUCKET = "hairfreelhr";
const REGION = "ap-south-1";

AWS.config.update({
  accessKeyId: "AKIAT3MF3N3L6LKHPZQP",
  secretAccessKey: "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",
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

function TechnicialApp() {
  let user = useSelector((e) => e.commonData);

  const [file, setFile] = useState([]);
  const [videoFile, setVideoFile] = useState([]);
  const [machineFile, setMachineFile] = useState([]);
  const [loader, setLoader] = useState(true);
  const [caseDetails, setCaseDetails] = useState([]);
  const [Parameters, setParameters] = useState(false);
  const navigate = useNavigate();

  const [termsPopup, setTermsPopup] = useState(false);
  const [paramPopup, setParamPopup] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);
  const [historyPopup, setHistoryPopup] = useState(false);
  const [breakReason, setBreakReason] = useState("");
  const [breakPopup, setBreakPopup] = useState(false);

  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const uploadFileToS3 = (file, path = "") => {
    setLoader(true);

    let filePath = path
      ? "customer/" +
        caseDetails?.customer_id +
        "/therapy/" +
        window.location.pathname.split("/")[2] +
        "/" +
        path +
        "/" +
        Date.now() +
        "-" +
        file.name
      : file.name;

    const params = {
      Body: file,
      Bucket: S3_BUCKET,
      Key: filePath,
    };

    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        setLoader(true);

        let percent = Math.round((evt.loaded / evt.total) * 100);
        setFileUploadProgress(percent);

        if (percent == 100) {
          setLoader(false);
          setFileUploadProgress(0);
        }
      })
      .send((err) => {
        if (err) console.log(err);
      });

    return (
      "https://" + S3_BUCKET + ".s3." + REGION + ".amazonaws.com/" + filePath
    );
  };

  let feedbackForm = [
    { que: " Hope the team was on time ?", ans: false },
    {
      que: " Are you satisfied with treatment at your expectations, overall experience?",
      ans: false,
    },
    {
      que: " hope you got full detail knowledge of treatment by therapist",
      ans: false,
    },
    { que: " The treatment was pain-free ?", ans: false },
    {
      que: " Would you recommend this treatment to a friend or family member?",
      ans: false,
    },
  ];
  const [feedbackQAlist, setFeedbackQAlist] = useState(feedbackForm);

  useEffect(() => {
    setLoader(true);
    if (user.auth) {
      getCaseDetails();
    }
    // else {
    //   navigate('/technician')
    // }
    // eslint-disable-next-line
  }, [user.auth]);

  const getCaseDetails = async () => {
    try {
      const { response_data } = await mainGetServices(
        `${CASESDETAILS}/${window.location.pathname.split("/")[2]}`
      );
      if (response_data) {
        setCaseDetails(response_data);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getThearpahyDetails = async (data) => {
    try {
      await mainPostServices(
        `${THERAPY}/${window.location.pathname.split("/")[2]}`,
        data
      );
      getCaseDetails();
    } catch (error) {
      console.log(error);
    }
  };

  const handleData = (name, value, type) => {
    let data;

    if (name === "complete_therapy") {
      if (!caseDetails?.parameter.hair_color.length) {
        return toast.error("complete the parameters");
      }
      if (caseDetails?.therapy?.break === 1) {
        return toast.error("please finish the break first");
      } else {
        data = { [name]: value };
      }
    } else {
      data = { [name]: value };
    }
    console.log("data", data);

    if (data) {
      setLoader(true);
      getThearpahyDetails(data);
    }
  };

  const handeConcern = () => {
    if (caseDetails?.therapy?.concern_form !== 1) {
      setTermsPopup(true);
    }
  };

  const handleAceept = async () => {
    setTermsPopup(false);
    let data = { concern_form: 1 };
    if (data) {
      setLoader(true);
      try {
        let res = await mainPostServices(
          `${THERAPY}/${window.location.pathname.split("/")[2]}`,
          data
        );
        if (res) {
          getCaseDetails();
          setTermsPopup(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSubmit = async () => {
    let data = { departure: 1 };
    setLoader(true);
    try {
      const { response_data } = await mainPostServices(
        `${THERAPY}/${window.location.pathname.split("/")[2]}`,
        data
      );
      if (response_data?.therapy?.departure === 1) {
        navigate(`/technician/calender`);
      }
      getCaseDetails();
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  const allowedImageTypes = ["image/jpg", "image/png", "image/jpeg"];
  const allowedVideoTypes = ["video/mp4", "video/mkv", "video/m4v"];

  const handleChange = async (event, name) => {
    var formData = new FormData();
    let file = event.target.files;
    console.log(file);
    var i = 0;

    if (file) {
      setLoader(true);
      if (name === "before_image") {
        if (
          Object.keys(event.target.files).some((_) =>
            allowedImageTypes.includes(event.target.files[_].type)
          )
        ) {
          setFile(file);
          for (i; i < file.length; i++) {
            let data = file[i];
            formData.append(
              "before_image[" + i + "]",
              uploadFileToS3(data, "before_image")
            );
          }
          fileUpload(formData);
        } else {
          alert("Please select a valid image file (JPEG or PNG)");
          setLoader(false);
        }
      }

      if (name === "machine_image") {
        if (
          Object.keys(event.target.files).some((_) =>
            allowedImageTypes.includes(event.target.files[_].type)
          )
        ) {
          setMachineFile(file);
          for (i; i < file.length; i++) {
            let data = file[i];
            formData.append(
              "machine_image[" + i + "]",
              uploadFileToS3(data, "machine_image")
            );
          }
          fileUpload(formData);
        } else {
          alert("Please select a valid image file (JPEG or PNG)");
          setLoader(false);
        }
      }
      if (name === "concern_video") {
        if (
          Object.keys(event.target.files).some((_) =>
            allowedVideoTypes.includes(event.target.files[_].type)
          )
        ) {
          setVideoFile(file);
          for (i; i < file.length; i++) {
            let data = file[i];
            formData.append(
              "concern_video",
              uploadFileToS3(data, "concern_video")
            );
          }
          fileUpload(formData);
        } else {
          alert("Please select a valid Video file (MP4 or MKV or M4V)");
          setLoader(false);
        }
      }
    }
  };

  const fileUpload = async (formData) => {
    try {
      const { response_data } = await mainPostServices(
        `${THERAPY}/${window.location.pathname.split("/")[2]}`,
        formData
      );
      console.log("response_data", response_data);
      getCaseDetails();
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleParam = (name, value) => {
    let data;
    data = { [name]: value };
    setLoader(true);
    getThearpahyDetails(data);
  };

  const handleParamSubmit = (event) => {
    event.preventDefault();
    setParamPopup(false);
  };

  const handleAns = (item, index) => {
    const arr = feedbackQAlist.map((a, i) => {
      if (i === index) {
        a.ans = !a.ans;
      }
      return a;
    });
    setFeedbackQAlist(arr);
  };

  const handleFeedback = async (event) => {
    event.preventDefault();
    let data = { feedback: feedbackQAlist };
    setLoader(true);
    try {
      const { response_data } = await mainPostServices(
        `${THERAPY}/${window.location.pathname.split("/")[2]}`,
        data
      );

      if (response_data) {
        setFeedbackPopup(false);
      }
      getCaseDetails();
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleParampop = () => {
    setParameters(!Parameters);
    setParamPopup(!paramPopup);
  };

  const handleBreak = (name, value) => {
    let data;
    if (value === 1) {
      if (breakReason.length) {
        data = { [name]: value, break_reason: breakReason };
      } else {
        alert("Please provide a break reason");
      }
    } else {
      data = { [name]: value };
    }
    if (data) {
      setLoader(true);
      getThearpahyDetails(data);
      setBreakReason("");
      setBreakPopup(false);
    }
  };

  return (
    <>
      {loader && <Loader progress={fileUploadProgress} />}
      <div className="container">
        <div className="dashboard">
          <header>
            <div className="header_left">
              <BackButton url={"/technician/calender"} />
            </div>

            <div className="header_center">
              <h1>
                {caseDetails?.customer?.first_name}{" "}
                {caseDetails?.customer?.last_name}
              </h1>
            </div>

            <div className="header_right">
              <button onClick={() => setHistoryPopup(!historyPopup)}>
                <svg
                  width="44"
                  height="38"
                  viewBox="0 0 44 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.0833 0.25C20.1105 0.25 15.3414 2.22544 11.8251 5.74175C8.30878 9.25805 6.33334 14.0272 6.33334 19H0.0833435L8.18751 27.1042L8.33334 27.3958L16.75 19H10.5C10.5 10.9375 17.0208 4.41667 25.0833 4.41667C33.1458 4.41667 39.6667 10.9375 39.6667 19C39.6667 27.0625 33.1458 33.5833 25.0833 33.5833C21.0625 33.5833 17.4167 31.9375 14.7917 29.2917L11.8333 32.25C13.5696 33.9959 15.6344 35.3807 17.9085 36.3247C20.1826 37.2687 22.6211 37.7531 25.0833 37.75C30.0562 37.75 34.8253 35.7746 38.3416 32.2582C41.8579 28.7419 43.8333 23.9728 43.8333 19C43.8333 14.0272 41.8579 9.25805 38.3416 5.74175C34.8253 2.22544 30.0562 0.25 25.0833 0.25ZM23 10.6667V21.0833L31.9167 26.375L33.4167 23.8542L26.125 19.5208V10.6667H23Z"
                    fill="white"
                  />
                </svg>
              </button>
              <button onClick={() => navigate(`/technician/dashboard`)}>
                <svg
                  width="42"
                  height="36"
                  viewBox="0 0 42 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 5.85417L31.4167 15.2292V31.5H27.25V19H14.75V31.5H10.5833V15.2292L21 5.85417ZM21 0.25L0.166656 19H6.41666V35.6667H18.9167V23.1667H23.0833V35.6667H35.5833V19H41.8333L21 0.25Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </header>

          <div className="dashboard_wrp">
            <div className="other_details">
              <div className="upload_image other_details_item">
                <h2> Upload images </h2>

                <div className="item_wrp">
                  <div style={{ position: "relative" }}>
                    <label htmlFor="upload" className="upload_section">
                      <img
                        src="/images/cloud-computing.svg"
                        alt="cloud-computing"
                      />
                      <span>Upload File</span>
                    </label>
                    <input
                      type="file"
                      id="upload"
                      multiple={true}
                      onChange={(e) => handleChange(e, "before_image")}
                      accept="image/*"
                      capture="environment"
                    />
                  </div>
                </div>
                <div className="preview_wrp">
                  {caseDetails?.therapy?.before_image
                    ? caseDetails?.therapy?.before_image.map((item, i) => {
                        return (
                          <div
                            className="perview_img"
                            style={{ marginBottom: "15px" }}
                            key={i}
                          >
                            <img src={item} alt="before_image" />
                          </div>
                        );
                      })
                    : file.name && (
                        <div>
                          <p> {file.name} </p>
                        </div>
                      )}
                </div>
              </div>
              {caseDetails?.inquiry?.visit_count > 1 ? (
                ""
              ) : (
                <div className="concern other_details_item">
                  <div className="concern_wrp">
                    <h2> Concern Form </h2>
                    <div className="toggle">
                      {caseDetails?.therapy?.concern_form === 1 ? (
                        <input
                          type="checkbox"
                          id="Concern"
                          checked={
                            caseDetails?.therapy?.concern_form === 1
                              ? true
                              : false
                          }
                          disabled={caseDetails?.therapy?.before_image === null}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          id="Concern"
                          onChange={(e) => handeConcern()}
                          checked={
                            caseDetails?.therapy?.concern_form === 1
                              ? true
                              : false
                          }
                          disabled={caseDetails?.therapy?.before_image === null}
                        />
                      )}
                      <label htmlFor="Concern"></label>
                    </div>
                  </div>
                </div>
              )}

              {caseDetails?.therapy?.concern_form === 1 && (
                <div className="upload_image other_details_item">
                  <h2> Upload Video </h2>
                  <div className="item_wrp">
                    <div style={{ position: "relative" }}>
                      <label htmlFor="concern_video" className="upload_section">
                        <img
                          src="/images/cloud-computing.svg"
                          alt="cloud-computing"
                        />
                        <span>Upload File</span>
                      </label>
                      <input
                        type="file"
                        id="concern_video"
                        multiple={true}
                        onChange={(e) => handleChange(e, "concern_video")}
                        disabled={caseDetails?.therapy?.before_image === null}
                        capture="environment"
                        accept="video/*"
                      />
                    </div>
                  </div>

                  {caseDetails?.therapy?.concern_video ? (
                    <div
                      className="perview_video"
                      style={{ marginBottom: "15px" }}
                    >
                      <video
                        src={caseDetails?.therapy?.concern_video}
                        id="video"
                        controls
                      ></video>
                    </div>
                  ) : (
                    videoFile.name && (
                      <div>
                        <p> {videoFile.name} </p>
                      </div>
                    )
                  )}
                </div>
              )}

              <div className="process other_details_item">
                <div className="item_wrp">
                  {caseDetails?.inquiry?.visit_count >= 1 ? (
                    <span
                      className={`blue_btn ${
                        caseDetails?.therapy?.before_image === null
                          ? "disable"
                          : ""
                      }`}
                      onClick={() => handleParampop()}
                    >
                      Parameters{" "}
                    </span>
                  ) : (
                    <span
                      className={`blue_btn ${
                        caseDetails?.therapy?.before_image === null ||
                        caseDetails?.therapy?.concern_video === null
                          ? "disable"
                          : ""
                      }`}
                      onClick={() => handleParampop()}
                    >
                      Parameters{" "}
                    </span>
                  )}
                </div>
              </div>

              <div className="item_wrp">
                {caseDetails?.therapy?.break === 1 ? (
                  <span
                    className={`timer_btn blue_btn ${
                      caseDetails?.therapy?.complete_therapy === 1
                        ? "disable"
                        : ""
                    } `}
                    onClick={() => handleBreak("break", 0)}
                  >
                    {" "}
                    Continue{" "}
                  </span>
                ) : (
                  <span
                    className={`timer_btn ${
                      caseDetails?.therapy?.complete_therapy === 1
                        ? "disable"
                        : ""
                    }`}
                    onClick={() => setBreakPopup(!breakPopup)}
                  >
                    {" "}
                    Break{" "}
                  </span>
                )}
              </div>

              <div className="process other_details_item">
                <h2> Therapy Process </h2>
                <div className="item_wrp">
                  {caseDetails?.inquiry?.visit_count >= 1 ? (
                    <span
                      className={`blue_btn ${
                        caseDetails?.parameter?.skin_color?.length === 0 ||
                        caseDetails?.therapy?.before_image === null ||
                        caseDetails?.therapy?.complete_therapy === 1
                          ? "disable"
                          : ""
                      } `}
                      onClick={() => handleData("complete_therapy", 1)}
                    >
                      {caseDetails?.therapy?.complete_therapy === 1
                        ? "Completed Therapy"
                        : "Complete Therapy"}
                    </span>
                  ) : (
                    <span
                      className={`blue_btn ${
                        caseDetails?.parameter?.skin_color?.length === 0 ||
                        caseDetails?.therapy?.before_image === null ||
                        caseDetails?.therapy?.concern_video === null ||
                        caseDetails?.therapy?.complete_therapy === 1
                          ? "disable"
                          : ""
                      } `}
                      onClick={() => handleData("complete_therapy", 1)}
                    >
                      {caseDetails?.therapy?.complete_therapy === 1
                        ? "Completed Therapy"
                        : "Complete Therapy"}
                    </span>
                  )}
                </div>
              </div>

              {caseDetails?.therapy?.complete_therapy === 1 && (
                <div className="upload_image other_details_item">
                  <h2> Upload Machine Image </h2>
                  <div className="item_wrp">
                    <div style={{ position: "relative" }}>
                      <label
                        htmlFor="machine_imageupload"
                        className="upload_section"
                      >
                        <img
                          src="/images/cloud-computing.svg"
                          alt="cloud-computing"
                        />
                        <span>Upload File</span>
                      </label>
                      <input
                        type="file"
                        id="machine_imageupload"
                        multiple={true}
                        onChange={(e) => handleChange(e, "machine_image")}
                        accept="image/*"
                        capture="environment"
                      />
                    </div>
                  </div>
                  <div className="preview_wrp">
                    {caseDetails?.therapy?.machine_image
                      ? caseDetails?.therapy?.machine_image.map((item, i) => {
                          return (
                            <div
                              className="perview_img"
                              style={{ marginBottom: "15px" }}
                              key={i}
                            >
                              <img src={item} alt="machine_image" />
                            </div>
                          );
                        })
                      : file.name && (
                          <div>
                            <p> {machineFile.name} </p>
                          </div>
                        )}
                  </div>
                </div>
              )}

              <div className="Advice other_details_item">
                <div className="concern_wrp">
                  <h2> Advice </h2>
                  <div className="toggle">
                    {caseDetails?.therapy?.advice === 1 ? (
                      <input
                        type="checkbox"
                        id="Advice"
                        checked={
                          caseDetails?.therapy?.advice === 1 ? true : false
                        }
                      />
                    ) : (
                      <input
                        type="checkbox"
                        id="Advice"
                        onChange={(e) =>
                          handleData("advice", e.target.checked, "switch")
                        }
                        checked={
                          caseDetails?.therapy?.advice === 1 ? true : false
                        }
                      />
                    )}
                    <label htmlFor="Advice"></label>
                  </div>
                </div>

                <div className="item_wrp">
                  <span
                    className={`blue_btn ${
                      caseDetails?.therapy?.machine_image === null ||
                      caseDetails?.therapy?.feedback?.length ||
                      caseDetails?.therapy?.complete_therapy !== 1
                        ? "disable"
                        : ""
                    } `}
                    onClick={() => setFeedbackPopup(true)}
                  >
                    Feedback
                  </span>

                  <div style={{ marginBottom: "40px" }}>
                    <label> Follow up date : </label>
                    <input
                      type="date"
                      name="followupdate"
                      defaultValue={caseDetails?.next_followup_date}
                      onChange={(e) =>
                        handleParam("followup_date", e.target.value)
                      }
                      disabled={caseDetails?.next_followup_date}
                    />
                  </div>

                  <span
                    className={`blue_btn 
                  ${
                    caseDetails?.parameter?.skin_color?.length === 0 ||
                    caseDetails?.therapy?.before_image === null ||
                    caseDetails?.therapy?.machine_image === null ||
                    caseDetails?.therapy?.feedback === null ||
                    caseDetails?.next_followup_date === null ||
                    caseDetails?.therapy?.complete_therapy !== 1
                      ? "disable"
                      : ""
                  } `}
                    onClick={() => handleSubmit()}
                  >
                    Departure
                  </span>
                </div>
              </div>
            </div>

            {breakPopup && (
              <div className="popup breakpopup">
                <div className="popup_content">
                  <div className="popup_title">
                    <h2> Break Reason </h2>
                  </div>
                  <div className="">
                    <fieldset>
                      <textarea
                        type="text"
                        name="break_reason"
                        value={breakReason}
                        placeholder="Break Reason"
                        onChange={(e) => setBreakReason(e.target.value)}
                        disabled={caseDetails?.therapy?.break === 1}
                      />
                    </fieldset>
                  </div>
                  <div className="parts_table_btn">
                    <button
                      className="pay_btn"
                      type="submit"
                      onClick={() => handleBreak("break", 1)}
                    >
                      {" "}
                      Break{" "}
                    </button>
                    <span
                      className="close_btn"
                      onClick={() => setBreakPopup(!breakPopup)}
                    >
                      {" "}
                      Cancel{" "}
                    </span>
                  </div>
                </div>
              </div>
            )}

            {/* termsPop */}
            {termsPopup && (
              <div className="popup termsPop">
                <div className="popup_title">
                  <h2> Terms and conditions</h2>
                </div>
                <div className="popup_content">
                  <div className="popup_content_wrp">
                    {/* <img src='/images/terms_condition.png' alt='terms_condition' /> */}
                    <p>
                      હું મારા પર Hair Remover/Reduction લેસરની મદદથી ટ્રીટમેન્ટ
                      કરવા માટે Hairfree LHR Center અનુભવી ડોકટર તથા જરૂરી તાલીમ
                      પામેલ સ્ટાફને ટ્રીટમેન્ટ કરવા અધિકાર અને મારી સંમતિ આપું
                      છું મારા શરીરના ભાગને ટ્રીટમેન્ટ આપવાની છે. મને લેસર
                      ટ્રીટમેન્ટના હેતુ અને લક્ષણો સમજાવ્યા છે. ટ્રીટમેન્ટને
                      લગતા મારા પ્રશ્નોના ઉતર મને સંતોષકારક રીતે આપવામાં આવેલ
                      છે.
                    </p>
                    <p>
                      {" "}
                      I authorize and give my consent to Hairfree LHR Center
                      experienced doctor and necessary trained staff to treat me
                      with the help of Hair Remover/Reduction laser to treat the
                      part of my body. I have been Explained the purpose and
                      features of laser treatment. My queries regarding
                      treatment have been satisfactorily answered.
                    </p>

                    <p>
                      मैं Hairfree LHR Center के अनुभवी डॉक्टर और ट्रेन स्टाफ़
                      को मेरे शरीर के हिस्से का इलाज करने के लिए हेयर
                      रिमूवर/रिडक्शन लेजर की मदद से मेरा इलाज करने के लिए अधिकृत
                      करता हूं और अपनी सहमति देता हूं। मुझे लेजर उपचार के
                      उद्देश्य और विशेषताओं के बारे में बताया गया है। उपचार के
                      संबंध में मेरे प्रश्नों का संतोषजनक उत्तर दिया गया है।
                    </p>

                    <p>
                      <strong>લેસર હેર રિમુવલ:</strong>
                      <br />
                      <br />
                      (૧) લેસર સારવાર ૧ થી ૨ મહિનાના ગાળે ૭ થી ૧૦ વખત સામાન્ય
                      રીતે કરાવવી પડે છે. ઘણીવાર શ્યામ ચામડી તથા પાતળા વાળ હોય
                      તો વધારે સીટીંગ લેવા પડે છે તથા ટચઅપ સેશનની જરૂર પડે છે.
                      <br />
                      (1) Laser treatment is usually done 7 to 10 times in a
                      period of 1 to 2 months. Often dark skin and thin hair
                      require more sittings and a touchup session. <br />
                      (1) लेजर उपचार आमतौर पर 1 से 2 महीने की अवधि में 7 से 10
                      बार किया जाता है। अक्सर गहरे रंग की त्वचा और पतले बालों के
                      लिए अधिक सत्र और टचअप सत्र की आवश्यकता होती है।
                    </p>

                    <p>
                      (૨) લેસર સારવાર દરમ્યાન સાધારણ ઝણઝાણાટી અને બળતરા થઈ શકે
                      છે. ભાગ્યે જ ચામઠા પડે છે તથા ચામડી કાળી થાય છે.
                      <br />
                      (2) Mild tingling and inflammation may occur during laser
                      treatment. Rarely skin burnt happens and the skin turns
                      into black. <br />
                      (2) लेजर उपचार के दौरान हल्की झुनझुनी और सूजन हो सकती है।
                      कभी-कभार ही त्वचा लाल / काली पड़ जाती है।
                    </p>

                    <p>
                      (૩) લેસર બાદ મારી ચામડી લાલ થઈ જવી કે બળતરા થવી તેવું શકય
                      છે. આ પ્રક્યિા કાયમી નથી અને થોડા દિવસ ચાલશે તેવું
                      સમજાવ્યું છે. <br />
                      (3) My skin may become red or inflamed after the laser.
                      It’s been explained to me that this condition is not
                      permanent and will take a few days to go. <br />
                      (3) लेज़र के बाद मेरी त्वचा लाल हो सकती है या उसमें सूजन आ
                      सकती है। मुझे समझाया गया है कि यह परिस्थिति हमेशा के लिये
                      नही है और इसे जाने में कुछ दिन लगेंगे।
                    </p>

                    <p>
                      (૪) લેસર હેર રિમુવલ એ નવું વિજ્ઞાન છે તેનાથી વાળ દુર થવાનુ
                      પરિણામ વતે ઓછે અંશે વ્યકિતગત હોય છે અને અનિચ્છિય વાળ દૂર
                      થવા વિશે કોઈ ગેરેંટી.નથી તેની મને જાણ છે. <br />
                      (4) Laser hair removal is a new science and the results of
                      hair removal are more or less depends on individual and I
                      know there is no guarantee about unwanted hair
                      removal/reduction.
                      <br />
                      (4) लेज़र हेयर रिमूवल एक नया विज्ञान है और बालों को हटाने
                      के परिणाम हर व्यक्ति पर निर्भर करते हैं और मुझे पता है कि
                      अनचाहे बालों को हटाने / कम करने की कोई गारंटी नहीं है।
                    </p>

                    <p>
                      (૫) લેસર હેર રિમુવલ પાતળા અને સફેદ વાળને અસર ન પણ કરે તે
                      મને સમજાવેલ છે.
                      <br />
                      (5) It has also been explained to me that Laser hair
                      removal treatment don’t work on thin and white hair.{" "}
                      <br />
                      (5) मुझे यह भी समझाया गया है कि पतले और सफेद बालों पर लेजर
                      हेयर रिमूवल ट्रीटमेंट काम नहीं करता है।
                    </p>

                    <p>
                      (૬) લેસર હેર રિમુવલ અંતઃસ્ત્રાવી ગ્રંથિની તકલીફો વાળા
                      પેશન્ટ માં ઘણીવાર જોઈએ તેટલું પરિણામ ન આપે તે મને સમજાવેલ
                      છે. <br />
                      (6) It has been explained to me that laser hair removal
                      often does not give the desired result in a patient with
                      endocrine gland problems. <br />
                      (6) मुझे यह समझाया गया है कि endocrine gland की समस्याओं
                      वाले रोगी में लेजर बालों को हटाने से अक्सर इच्छित परिणाम
                      नहीं मिलता है।
                    </p>
                    <br />
                    <p>
                      Machine Name : <u>MILESMEN DIODE LASER</u> Procedure Name
                      : <u>LASER HAIR REDUCTION TREATMENT</u>{" "}
                    </p>
                    <p>
                      <br />
                      I Understand that the Hair Removal/Reduction is meant for
                      the treatment. The Clinical result may vary from subject
                      to subject clinic (Hairfree LHR Center) has provided all
                      the information about machine, its working, side effects
                      and Result of the Treatment. The payment and Fees
                      Structure is Informed. Payment is non-refundable. I
                      understand that the multiple sittings are required for
                      full treatment. All the questions asked are answered
                      satisfactorily with all the knowledge about the Laser
                      treatment.
                      <br />
                      <br />
                      I am ready to undergo, the treatment from the experienced
                      & trained staff of Hairfree LHR Center. <br />
                    </p>

                    <p>
                      લેસર ટ્રીટમેન્ટ અંગેની તમામ માહિતી મેળવ્યા પછી હું સારવાર
                      કરાવવા સંમત છું જે બદલ નીચે સહી કરી આપું છું. <br />
                      after getting all the information of the hair
                      removal/reduction treatment i agree for treatment on me,
                      for the Area I selected.
                      <br />
                      बालों को हटाने / कम करने के उपचार की सभी जानकारी प्राप्त
                      करने के बाद, मैं अपने द्वारा चुने गए क्षेत्र के लिए उपचार
                      के लिए सहमत हूं।
                    </p>
                  </div>
                </div>
                <div className="popup_footer">
                  <span className="blue_btn" onClick={() => handleAceept()}>
                    {" "}
                    Accept{" "}
                  </span>
                </div>
              </div>
            )}

            {/* paramPopup */}
            {paramPopup && (
              <div className="popup paramters">
                <div className="popup_title">
                  <h2> PARAMETERS </h2>
                </div>
                <form onSubmit={(event) => handleParamSubmit(event)}>
                  <div className="popup_content paramters">
                    <div className="popup_content_wrp">
                      <div className="paramters_item">
                        <h3>Skin Color</h3>
                        <div className="paramters_item_content">
                          <fieldset className="very_light">
                            <input
                              id="very_light"
                              type="radio"
                              name="skincolor"
                              onChange={(e) =>
                                handleParam("skin_color", "very_light")
                              }
                              required
                              checked={
                                caseDetails.parameter.skin_color ===
                                "very_light"
                              }
                            />
                            <label htmlFor="very_light">Very Light</label>
                          </fieldset>
                          <fieldset className="light">
                            <input
                              id="light"
                              type="radio"
                              name="skincolor"
                              onChange={(e) =>
                                handleParam("skin_color", "light")
                              }
                              required
                              checked={
                                caseDetails.parameter.skin_color === "light"
                              }
                            />
                            <label htmlFor="light"> Light</label>
                          </fieldset>
                          <fieldset className="medium">
                            <input
                              id="medium"
                              type="radio"
                              name="skincolor"
                              onChange={(e) =>
                                handleParam("skin_color", "medium")
                              }
                              required
                              checked={
                                caseDetails.parameter.skin_color === "medium"
                              }
                            />
                            <label htmlFor="medium"> Medium</label>
                          </fieldset>
                          <fieldset className="medium_dark">
                            <input
                              id="medium_dark"
                              type="radio"
                              name="skincolor"
                              onChange={(e) =>
                                handleParam("skin_color", "medium_dark")
                              }
                              required
                              checked={
                                caseDetails.parameter.skin_color ===
                                "medium_dark"
                              }
                            />
                            <label htmlFor="medium_dark">Medium dark</label>
                          </fieldset>
                          <fieldset className="dark">
                            <input
                              id="dark"
                              type="radio"
                              name="skincolor"
                              onChange={(e) =>
                                handleParam("skin_color", "dark")
                              }
                              required
                              checked={
                                caseDetails.parameter.skin_color === "dark"
                              }
                            />
                            <label htmlFor="dark">Dark</label>
                          </fieldset>
                          <fieldset className="black">
                            <input
                              id="black"
                              type="radio"
                              name="skincolor"
                              onChange={(e) =>
                                handleParam("skin_color", "black")
                              }
                              required
                              checked={
                                caseDetails.parameter.skin_color === "black"
                              }
                            />
                            <label htmlFor="black">Black</label>
                          </fieldset>
                        </div>
                      </div>
                      <div className="paramters_item haircolor">
                        <h3>Hair Color</h3>
                        <div className="paramters_item_content">
                          <fieldset className="black">
                            <input
                              id="haircolorblack"
                              type="radio"
                              name="haircolor"
                              onChange={(e) =>
                                handleParam("hair_color", "black")
                              }
                              required
                              checked={
                                caseDetails.parameter.hair_color === "black"
                              }
                            />
                            <label htmlFor="haircolorblack">Black</label>
                          </fieldset>
                          <fieldset className="brown">
                            <input
                              id="haircolorbrown"
                              type="radio"
                              name="haircolor"
                              onChange={(e) =>
                                handleParam("hair_color", "brown")
                              }
                              required
                              checked={
                                caseDetails.parameter.hair_color === "brown"
                              }
                            />
                            <label htmlFor="haircolorbrown"> Brown</label>
                          </fieldset>
                          <fieldset className="light">
                            <input
                              id="haircolorlight"
                              type="radio"
                              name="haircolor"
                              onChange={(e) =>
                                handleParam("hair_color", "light")
                              }
                              required
                              checked={
                                caseDetails.parameter.hair_color === "light"
                              }
                            />
                            <label htmlFor="haircolorlight"> Light </label>
                          </fieldset>
                          <fieldset className="blonde">
                            <input
                              id="haircolorblonde"
                              type="radio"
                              name="haircolor"
                              onChange={(e) =>
                                handleParam("hair_color", "blonde")
                              }
                              required
                              checked={
                                caseDetails.parameter.hair_color === "blonde"
                              }
                            />
                            <label htmlFor="haircolorblonde"> Blonde </label>
                          </fieldset>
                        </div>
                      </div>

                      <div className="paramters_item hairdiameter">
                        <h3> Hair Diameter</h3>
                        <div className="paramters_item_content">
                          <fieldset className="fine">
                            <input
                              id="hairdiameterfine"
                              type="radio"
                              name="hairdiameter"
                              onChange={(e) =>
                                handleParam("hair_diameter", "fine")
                              }
                              required
                              checked={
                                caseDetails.parameter.hair_diameter === "fine"
                              }
                            />
                            <label htmlFor="hairdiameterfine">
                              <img src="/images/follicle.png" alt="hair" />
                              <span> Fine </span>
                            </label>
                          </fieldset>
                          <fieldset className="medium">
                            <input
                              id="hairdiametermedium"
                              type="radio"
                              name="hairdiameter"
                              onChange={(e) =>
                                handleParam("hair_diameter", "medium")
                              }
                              required
                              checked={
                                caseDetails.parameter.hair_diameter === "medium"
                              }
                            />
                            <label htmlFor="hairdiametermedium">
                              <img src="/images/follicle.png" alt="hair" />
                              <span> Medium </span>
                            </label>
                          </fieldset>
                          <fieldset className="coarse">
                            <input
                              id="hairdiametercoarse"
                              type="radio"
                              name="hairdiameter"
                              onChange={(e) =>
                                handleParam("hair_diameter", "coarse")
                              }
                              required
                              checked={
                                caseDetails.parameter.hair_diameter === "coarse"
                              }
                            />
                            <label htmlFor="hairdiametercoarse">
                              <img src="/images/follicle.png" alt="hair" />
                              <span> Coarse </span>
                            </label>
                          </fieldset>
                        </div>
                      </div>

                      <div className="paramters_item workscreen">
                        <h3> Work Screen </h3>
                        <div className="paramters_item_content">
                          <fieldset className="fluence">
                            <label htmlFor="fluence">
                              {" "}
                              Fluence (J/cm<sup>2</sup>)
                            </label>
                            <input
                              id="fluence"
                              type="number"
                              name="fluence"
                              placeholder="0"
                              defaultValue={caseDetails?.parameter?.fluence}
                              onChange={(e) =>
                                handleParam("fluence", e.target.value)
                              }
                              required
                            />
                          </fieldset>
                          <fieldset className="pluseWidth">
                            <label htmlFor="pluseWidth">
                              {" "}
                              Pluse Width (ms)
                            </label>
                            <input
                              id="pluseWidth"
                              type="number"
                              name="Pluse Width"
                              placeholder="0"
                              onChange={(e) =>
                                handleParam("pluse_width", e.target.value)
                              }
                              required
                              defaultValue={caseDetails?.parameter?.pluse_width}
                            />
                          </fieldset>
                          <fieldset className="frequency">
                            <label htmlFor="frequency"> Frequency (Hz) </label>
                            <input
                              id="frequency"
                              type="number"
                              name="frequency"
                              placeholder="0"
                              onChange={(e) =>
                                handleParam("frequency", e.target.value)
                              }
                              required
                              defaultValue={caseDetails?.parameter?.frequency}
                            />
                          </fieldset>
                        </div>
                      </div>
                    </div>
                    <div className="popup_footer">
                      <button className="blue_btn" type="submit">
                        {" "}
                        Submit{" "}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}

            {/* paramPopup */}
            {feedbackPopup && (
              <div className="popup feedback">
                <div className="popup_title">
                  <h2> Feedback </h2>
                </div>
                <form onSubmit={(event) => handleFeedback(event)}>
                  <div className="popup_content feedback">
                    <div className="popup_content_wrp">
                      <div className="feedback_item">
                        {feedbackQAlist.map((item, i) => {
                          return (
                            <div className="que" key={i}>
                              <p>{` ${i + 1}) ${item.que} `}</p>
                              <div className="ans">
                                No
                                <div className="toggle">
                                  <input
                                    type="checkbox"
                                    id={`que${i}`}
                                    name="ans"
                                    checked={item.ans}
                                    onChange={() => handleAns(item, i)}
                                  />
                                  <label htmlFor={`que${i}`}></label>
                                </div>
                                Yes
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="popup_footer">
                        <button className="blue_btn" type="submit">
                          {" "}
                          Submit{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>

      {historyPopup && (
        <div className="popup history">
          <HistoryPopup
            setHistoryPopup={setHistoryPopup}
            customerID={caseDetails?.customer_id}
          />
        </div>
      )}
    </>
  );
}

export default TechnicialApp;
