import React, { useEffect, useState } from "react";
import {
  ACTIVEPARTS,
  CASESDETAILS,
  mainPostServices,
  THERAPY,
} from "../shared/services";
import Loader from "./Loader";
import { toast } from "react-toastify";

function SelectParts({ close, caseInfo, getCaseDetails }) {
  const [loader, setLoader] = useState(false);
  const [partList, setPartList] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [enabledItems, setEnabledItems] = useState({});

  useEffect(() => {
    getPartList();
  }, []);

  const getPartList = async () => {
    setLoader(true);
    try {
      caseInfo.active_part_ids.map((item, i) => {
        checkedItems[item] = true;
      });

      setPartList(caseInfo["part_and_sessions"]);
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  const hadleSubmit = async (e) => {
    if (Object.keys(checkedItems).length === 0) {
      alert("Please Select First");
    } else {
      setLoader(true);
      Object.entries(checkedItems).forEach(([key, value]) => {
        if (value) {
          enabledItems[key] = value;
        }
      });
      let data = {
        enabled_part_session_ids: Object.keys(enabledItems),
      };

      try {
        const { response_type } = await mainPostServices(
          `${THERAPY}/${
            window.location.pathname.split("/")[2]
          }/update_active_parts`,
          data
        );
        if (response_type === "success") {
          toast.success(response_type);
          getCaseDetails();
          setLoader(false);
          close(false);
        } else {
          setLoader(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className="popup editpart">
        <div className="popup_title">
          <h2> Select Parts </h2>
        </div>
        <div className="popup_content ">
          <div className="appointment_wrp">
            <div className="parts">
              <div className="form_section">
                {loader && <Loader />}
                <div className="appoint_form">
                  <div className="appoint_form_wrp">
                    {partList?.map((item, i) => {
                      return (
                        <div className="appoint_form_title_panel" key={i}>
                          <label
                            htmlFor={`selectpart_${item.id}`}
                            className="parts"
                          >
                            <div className="parts_left">
                              <div>
                                <img src={item.part.icon} alt="part_icon" />
                              </div>
                              <span> {item.part.name}</span>
                            </div>
                            <ul className="parts_right">
                              <li
                                style={{
                                  maxWidth: "85px",
                                  backgroundColor: `${
                                    checkedItems[item.id] ? "#49A02C" : ""
                                  }`,
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setCheckedItems((prevState) => ({
                                    ...prevState,
                                    [item.id]: !prevState[item.id],
                                  }));
                                }}
                              >
                                {checkedItems[item.id] ? "Selected" : "Select"}
                              </li>
                              {/* <li className="cost">View</li> */}
                            </ul>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="parts_table_btn">
                <button
                  className="pay_btn"
                  style={{ marginBottom: "10px", marginTop: "0" }}
                  type="button"
                  onClick={hadleSubmit}
                >
                  {" "}
                  Submit{" "}
                </button>
                <button
                  className="close_btn"
                  style={{ marginBottom: "10px", marginTop: "0" }}
                  type="button"
                  onClick={() => close(false)}
                >
                  {" "}
                  Cancel{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(SelectParts);
