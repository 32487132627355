import React, { useEffect, useState } from 'react'
import { HISTROY, mainGetServices } from '../shared/services';
import Loader from './Loader';
import Accordion from './Accordion';

function HistoryPopup({ setHistoryPopup, customerID }) {
    const [historyData, setHistoryData] = useState([])
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        getCaseDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCaseDetails = async () => {
        setLoader(true)
        try {
            const data = await mainGetServices(`${HISTROY}/${customerID}`);
            if (data) {
                setHistoryData(data)
                setLoader(false)
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            {loader && <Loader />}
            <div className='popup_title'>
                <h2> Histroy </h2>
            </div>
            <div className='popup_content histotypopup'>
                <div className='popup_content_wrp'>
                    {historyData?.inquiry_cases?.length ?
                        historyData?.inquiry_cases?.map((item, i) => {
                            return <Accordion data={item} key={i} />
                        })
                        :
                        <p className='nodataFound'>No History Found</p>
                    }
                </div>
                <div className='parts_table_btn'>
                    <button className="close_btn" onClick={() => setHistoryPopup(false)} > Close </button>
                </div>
            </div>

        </>
    )
}

export default HistoryPopup