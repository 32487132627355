import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
function SelectDate({ ds, state }) {
  // const [currentDay, setCurrentDay] = useState(new Date());
  // const [currentMonth, setCurrentMonth] = useState(new Date());
  // const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    // if (state?.appointmentDate) {
    //   setCurrentDay(new Date(state?.appointmentDate))
    //   setCurrentMonth(new Date(state?.appointmentDate))
    //   setCurrentYear(new Date(state?.appointmentDate).getFullYear())
    // }
    // else {
    ds({ appointmentDate: new Date() })
    // }
    // eslint-disable-next-line
  }, [])


  const handlSubmit = (e) => {
    const userInputDate = `${startDate.getDate()}/${startDate.toLocaleString('default', { month: 'numeric' })}/${startDate.getFullYear()}`;
    // const checkDate = `${currentMonth.toLocaleString('default', { month: 'numeric' })}/${currentDay.getDate()}/${currentYear}`;
    // if (new Date(new Date(checkDate).toDateString()) > new Date(new Date().toDateString())) {
    ds({ step: 4, appointmentDate: userInputDate })
    // } else {
    //   alert('Please Select Next Day Date');
    // }
  }


  // const handleNextMonth = () => {
  //   const nextMonth = new Date(currentMonth);
  //   nextMonth.setMonth(nextMonth.getMonth() + 1);
  //   setCurrentMonth(nextMonth);
  //   setCurrentDay(nextMonth)
  // };

  // const handlePrevMonth = () => {
  //   const prevMonth = new Date(currentMonth);
  //   prevMonth.setMonth(prevMonth.getMonth() - 1);
  //   setCurrentMonth(prevMonth);
  //   setCurrentDay(prevMonth)
  // };

  // const handleNextDay = () => {
  //   const nextDay = new Date(currentDay);
  //   nextDay.setDate(nextDay.getDate() + 1);
  //   setCurrentDay(nextDay);
  //   setCurrentMonth(nextDay);
  // };

  // const handlePrevDay = () => {
  //   const prevDay = new Date(currentDay);
  //   prevDay.setDate(prevDay.getDate() - 1);
  //   setCurrentDay(prevDay);
  //   setCurrentMonth(prevDay);
  // };


  // const handleNextYear = () => {
  //   setCurrentYear(currentYear + 1);
  // };

  // const handlePrevYear = () => {
  //   setCurrentYear(currentYear - 1);
  // };


  return (
    <>
      <header>
        {state.step > 1 && <button onClick={() => ds({ step: 2 })}><svg width="37px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M18.807 23l-6.67-6.67a.466.466 0 0 1 0-.66L18.807 9" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="#39464e"></path></svg></button>}
        <h1>Select Date</h1>
      </header>
      <div className='form_section select_date'>
        <div className='steptwo stepthree' >
          {/* <div className='custom_datepicker'>
            <div className='custom_datepicker_item'>
              <button onClick={() => handleNextMonth()} className="next"> + </button>
              <h2>  {currentMonth.toLocaleString('default', { month: 'short' })} </h2>
              <button onClick={() => handlePrevMonth()} className="prev"> - </button>
            </div>

            <div className='custom_datepicker_item dateslector'>
              <button onClick={() => handleNextDay()} className="next"> + </button>
              <h2> {currentDay.toLocaleDateString('default', { day: 'numeric' })}</h2>
              <button onClick={() => handlePrevDay()} className="prev" disabled={(currentDay.getDate() === new Date().getDate()) && (currentDay.getMonth() === new Date().getMonth())}> - </button>
            </div>

            <div className='custom_datepicker_item'>
              <button onClick={() => handleNextYear()} className="next"> + </button>
              <h2>{currentYear}</h2>
              <button onClick={() => handlePrevYear()} disabled={currentYear === new Date().getFullYear()} className="prev"> - </button>
            </div>
          </div> */}
          <div style={{ textAlign: "center" }}>
            <ReactDatePicker
              selected={startDate}
              minDate={new Date()}
              onChange={(date) => setStartDate(date)}
              inline />
          </div>

          <fieldset>
            <label>
              Select Shift Schedule :
            </label>
            <select value={state.shift} name="shift" onChange={(e) => ds({ [e.target.name]: e.target.value })}>
              <option value="Morning" > Morning </option>
              <option value="Afternoon" > Afternoon </option>
              <option value="Evening" > Evening </option>
            </select>
          </fieldset>
          <button className='next_button' onClick={() => handlSubmit()}> Next </button>
        </div>
      </div>
    </>
  )
}

export default React.memo(SelectDate) 