import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function Dashboard() {
    const dispatch = useDispatch();
    let userData = useSelector(e => e.commonData.userData);
    // let Auth = useSelector(e => e.commonData?.auth)
    const navigate = useNavigate();
    // const dispatch = useDispatch();

    const logout = () => {
        localStorage.removeItem("token")
        dispatch({ type: "USER_INFO", auth: false });
        toast.success('successfully logged out');
        navigate('/technician')
    }
    return (
        <>
            {/* {console.log("userData", userData)} */}

            <div className='container' style={{ backgroundColor: "#fff" }}>
                <div className='details'>
                    <header>
                        <h1> Dashboard </h1>
                        <span className='logout' onClick={() => logout()}>
                            <svg viewBox="0 0 12 12" enableBackground="new 0 0 12 12" fill="#ffffff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><polygon fill="#ffffff" points="9,2 9,0 1,0 1,12 9,12 9,10 8,10 8,11 2,11 2,1 8,1 8,2 "></polygon><polygon fill="#ffffff" points="8.2929688,3.2929688 7.5859375,4 9.0859375,5.5 5,5.5 5,6.5 9.0859375,6.5 7.5859375,8 8.2929688,8.7070313 11,6 "></polygon></g></svg>
                        </span>
                    </header>
                    <div className='user_deatil'>
                        <h2>Welcome {userData?.name}  </h2>
                        <span className='balance'> {userData?.role_associated_id} </span>
                    </div>
                </div>

                <div className='dashboard home_dash'>
                    <div className='dashboard_wrp'>
                        <div className='home_dash_wrp'>
                            <div className='home_dash_wrp_item'
                                onClick={() => navigate('/technician/calender')}
                            >
                                <div className='btn_icon'>
                                    <img src='/images/calendar.png' alt='calendar' />
                                </div>
                                <span> My Task </span>
                            </div>
                            <div className='home_dash_wrp_item' onClick={() => navigate('/inquiry')}>
                                <div className='btn_icon'>
                                    <img src='/images/stock.png' alt='stock' />
                                </div>
                                <span> My Stock </span>

                            </div>
                            <div className='home_dash_wrp_item' onClick={() => navigate('/inquiry')}>
                                <div className='btn_icon'>
                                    <img src='/images/walkin.png' alt='walkin' />
                                </div>
                                <span>   Walk In </span>

                            </div>
                            <div className='home_dash_wrp_item' onClick={() => navigate('/inquiry')}>
                                <div className='btn_icon'>
                                    <img src='/images/reference.png' alt='reference' />
                                </div>
                                <span> My Reference </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard