import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CASES, mainGetServices } from "../shared/services";
import Loader from "./Loader";
import { toast } from "react-toastify";
import BackButton from "./BackButton";

function Calender() {
  let userData = useSelector((e) => e.commonData.userData);
  let Auth = useSelector((e) => e.commonData?.auth);

  const [loader, setLoader] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [formattedStartDate, setformattedStartDate] = useState("");
  const [endDate, setEndDate] = useState(new Date());
  const [formattedEndDate, setformattedEndDate] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [searchText, setSearchText] = useState("");
  const [appointment, setAppointment] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoader(true);
    if (Auth) {
      const originalDate = new Date();
      const year = originalDate.getFullYear();
      const month = originalDate.getMonth() + 1;
      const day = originalDate.getDate();
      const formattedDate = `${year}/${month.toString().padStart(2, "0")}/${day
        .toString()
        .padStart(2, "0")}`;
      setformattedStartDate(formattedDate);
      setformattedEndDate(formattedDate);
      getFilterData(formattedDate, formattedDate);
    }
    // eslint-disable-next-line
  }, [Auth]);

  const getFilterData = async (start, end) => {
    setLoader(true);
    if (start.length > 0 && end.length > 0) {
      try {
        const { response_data, response_type, response } =
          await mainGetServices(
            `${CASES}?filter_from=${start}&filter_to=${end}`
          );

        if (response_type === "success") {
          setAppointment(response_data);
        } else {
          toast.error(response.data.response_data.filter_to[0]);
        }

        setLoader(false);
      } catch (error) {
        setLoader(false);
      }
    }
    setLoader(false);
  };

  const startDateformat = (date) => {
    setStartDate(date);
    const originalDate = new Date(date);
    const year = originalDate.getFullYear();
    const month = originalDate.getMonth() + 1;
    const day = originalDate.getDate();
    const formattedDate = `${year}/${month.toString().padStart(2, "0")}/${day
      .toString()
      .padStart(2, "0")}`;
    setformattedStartDate(formattedDate);
    getFilterData(formattedDate, formattedEndDate);
  };

  const endDateformat = (date) => {
    setEndDate(date);
    const originalDate = new Date(date);
    const year = originalDate.getFullYear();
    const month = originalDate.getMonth() + 1;
    const day = originalDate.getDate();
    const formattedDate = `${year}/${month.toString().padStart(2, "0")}/${day
      .toString()
      .padStart(2, "0")}`;
    setformattedEndDate(formattedDate);
    getFilterData(formattedStartDate, formattedDate);
  };

  const handleSearch = async (e) => {
    setSearchText(e.target.value);
    setLoader(true);
    try {
      const { response_data, response_type, response } = await mainGetServices(
        `${CASES}?search=${e.target.value}`
      );
      if (response_type === "success") {
        setAppointment(response_data);
      } else {
        toast.error(response.data.response_data.filter_to[0]);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <>
      {loader && <Loader />}

      <div className="container">
        <div className="dashboard">
          <header>
            <BackButton url={"/technician/dashboard"} />
            <h1> {userData?.name}</h1>
            <button onClick={() => navigate(`/technician/dashboard`)}>
              <svg
                width="42"
                height="36"
                viewBox="0 0 42 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 5.85417L31.4167 15.2292V31.5H27.25V19H14.75V31.5H10.5833V15.2292L21 5.85417ZM21 0.25L0.166656 19H6.41666V35.6667H18.9167V23.1667H23.0833V35.6667H35.5833V19H41.8333L21 0.25Z"
                  fill="white"
                />
              </svg>
            </button>
          </header>

          <div className="select_date">
            <div className="from_date">
              <label> From </label>
              <ReactDatePicker
                selected={startDate}
                dateFormat="dd-MM-yyyy"
                onChange={(date) => startDateformat(date)}
              />
            </div>
            <div className="to_date">
              <label> To </label>
              <ReactDatePicker
                selected={endDate}
                dateFormat="dd-MM-yyyy"
                onChange={(date) => endDateformat(date)}
              />
            </div>
          </div>
          <div className="searchbar">
            <input
              type="text"
              placeholder="Enter Search"
              onChange={(e) => handleSearch(e)}
            />
          </div>
          <div className="dashboard_wrp">
            <div className="technician">
              <div className="view_all">
                <h2>Upcoming Appoinment </h2>
              </div>
              <div className="technician_list">
                {appointment.length ? (
                  appointment.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className="technician_list_item"
                        onClick={() =>
                          navigate(`/technician/${item.id}/detail`)
                        }
                      >
                        <div className="technician_list_item_header">
                          <div className="user">
                            <img
                              src={`${
                                item?.imgurl
                                  ? "imgurl"
                                  : item.customer.gender === "female"
                                  ? "/images/female.png"
                                  : "/images/male.png"
                              }`}
                              alt="user"
                            />{" "}
                          </div>
                          <div className="user_name">
                            {" "}
                            <h3>
                              {" "}
                              {item?.customer?.first_name}{" "}
                              {item?.customer?.last_name}{" "}
                            </h3>
                          </div>
                          <div className="user_info">
                            <img src="/images/info.svg" alt="info" />
                          </div>
                        </div>
                        <div className="technician_list_item_tl">
                          <div className="technician_list_item_tl_l">
                            <img src="/images/date.svg" alt="date" />
                            <span>{item.schedule_date} </span>
                          </div>
                          <div className="technician_list_item_tl_r">
                            <img src="/images/location.svg" alt="location" />
                            <span> {item.customer.address} </span>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center" style={{ padding: "30px 0" }}>
                    <p> Case Data not found!</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Calender;
