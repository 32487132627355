import axios from "axios";
import { toast } from "react-toastify";

const api = axios.create();
api.defaults.baseURL = "https://hairfreeindia.in/api";
// api.defaults.baseURL = "http://127.0.0.1:8000/api";

export const setToken = (token) => {
  if (token) api.defaults.headers.common = { Authorization: `Bearer ${token}` };
  else delete api.defaults.headers.common["Authorization"];
};

// Api end point
export const PARTS = `/parts`;
export const INQUIRY = `/inquiry`;
export const LOGIN = `/technician/login`;
export const AUTH = `/technician/auth-user`;
export const CASES = `/technician/calender`;
export const CASESDETAILS = `/technician/case`;
export const THERAPY = `/technician/therapy`;
export const HISTROY = `/technician/history`;

export const mainPostServices = (url, data) =>
  api.post(url, data).then(onSuccess, onError);
export const mainGetServices = (url, data) =>
  api.get(url, data).then(onSuccess, onError);

function onSuccess(response) {
  return response.data;
}
export function onError(response, error) {
  toast.error(response.response.data.response_data[0]);
  return response;
}
