import React from "react";

function Loader({ progress }) {
  return (
    <div
      className="loader"
      style={{
        webkitBackdropFilter: "blur(8px)",
        backdropFilter: "blur(8px)",
      }}
    >
      <span></span>
      <div
        style={{
          position: "absolute",
          marginTop: "125px",
          visibility: `${progress && progress > 0 ? "visible" : "hidden"}`,
        }}
      >
        <b>{progress}%</b>
      </div>
    </div>
  );
}

export default Loader;
