import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

const api = axios.create();
api.defaults.baseURL = "https://hairfreeindia.in/api";

export const numberGetServices = (url, data) =>
  api.get(url, data).then(onSuccess, onError);
function onSuccess(response) {}
export function onError(response, error) {
  alert(response.response.data.response_data[0]);
  return response;
}

function Details({ ds, state }) {
  const [reference, setReference] = useState(false);
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const referredBy = urlParams.get("reference");
    if (referredBy) {
      setReference(true);
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    if (name === "dob") {
      const date = new Date(value);
      const today = new Date();
      const calculatedAge = today.getFullYear() - date.getFullYear();
      ds({ age: calculatedAge });
    }

    if (["mobile", "alternativeMobile"].includes(name)) {
      ds({ [name]: value });
    } else {
      ds({ [name]: value });
    }
  };

  const validateDOB = (dob) => {
    const inputDate = new Date(dob);
    const currentDate = new Date();

    if (inputDate > currentDate) {
      // DOB is in the future
      toast.warning(" DOB is not valid");
      return false;
    }

    const diffInMs = currentDate.getTime() - inputDate.getTime();
    const ageDate = new Date(diffInMs);
    const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);

    return true;
    if (calculatedAge >= 18 && calculatedAge <= 100) {
      return true;
    } else {
      toast.warning("Please enter a valid date between 18 and 100 years");
      return false;
    }
  };

  const handlSubmit = async (e) => {
    e.preventDefault();
    const isDOBValid = validateDOB(state?.dob);
    if (isDOBValid) {
      ds({ step: 3 });
    }
  };

  const checkNumber = async (e) => {
    await numberGetServices(`/check-customer?mobile=${e.target.value}`);
  };

  return (
    <>
      <header>
        {state?.step > 1 && (
          <button onClick={() => ds({ step: 1 })}>
            <svg
              width="37px"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.807 23l-6.67-6.67a.466.466 0 0 1 0-.66L18.807 9"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                stroke="#39464e"
              ></path>
            </svg>
          </button>
        )}
        <h1>Personal Details</h1>
      </header>
      <div className="form_section">
        <form className="appoint_form stepthree" onSubmit={handlSubmit}>
          <div className="appoint_form_wrp">
            <fieldset>
              <label>
                {" "}
                Patient Name <span className="req-red">*</span> :
              </label>
              <div className="fname">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={state?.firstName}
                  onChange={(e) => handleChange(e)}
                  required
                />
                <input
                  type="text"
                  name="middleName"
                  placeholder="Middle Name"
                  value={state?.middleName}
                  onChange={(e) => handleChange(e)}
                  required
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={state?.lastName}
                  onChange={(e) => handleChange(e)}
                  required
                />
              </div>
            </fieldset>

            <fieldset className="gender">
              <label> Gender:</label>
              <div>
                <input
                  type="radio"
                  name="gender"
                  value="male"
                  id="male"
                  onChange={(e) => handleChange(e)}
                  checked={state?.gender === "male"}
                />
                <label htmlFor="male"> Male </label>
                <input
                  type="radio"
                  name="gender"
                  value="female"
                  id="female"
                  onChange={(e) => handleChange(e)}
                  checked={state?.gender === "female"}
                />
                <label htmlFor="female"> Female </label>
                <input
                  type="radio"
                  name="gender"
                  value="other"
                  id="other"
                  onChange={(e) => handleChange(e)}
                  checked={state?.gender === "other"}
                />
                <label htmlFor="other"> Other </label>
              </div>
            </fieldset>

            <fieldset>
              <label> Email:</label>
              <input
                type="email"
                name="email"
                placeholder="Enter Your Email Here"
                value={state?.email}
                onChange={(e) => handleChange(e)}
              />
            </fieldset>

            <fieldset>
              <label>
                {" "}
                DOB <span className="req-red">*</span> :
              </label>
              <input
                type="date"
                placeholder="MM/DD/YYYY"
                name="dob"
                value={state?.dob}
                pattern="(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/]\d{4}"
                onChange={(e) => handleChange(e)}
                required
              />
              {state?.dob && (
                <span className="age"> Your Age : {state?.age} years</span>
              )}
            </fieldset>

            <fieldset>
              <label> Occupation:</label>
              <input
                type="text"
                name="occupation"
                placeholder="Enter Your Occupation"
                value={state?.occupation}
                onChange={(e) => handleChange(e)}
              />
            </fieldset>

            <fieldset>
              <label>
                {" "}
                Mobile <span className="req-red">*</span> :
              </label>
              <input
                type="number"
                name="mobile"
                placeholder="Enter Your Mobile Number"
                maxLength="10"
                value={state?.mobile}
                onChange={(e) => handleChange(e)}
                required
                onBlur={(e) => checkNumber(e)}
              />
            </fieldset>

            <fieldset>
              <label> Alternative Mobile:</label>
              <input
                type="number"
                name="alternativeMobile"
                placeholder="Enter Your Alternative Mobile Number"
                maxLength="10"
                value={state?.alternativeMobile}
                onChange={(e) => handleChange(e)}
              />
            </fieldset>

            <fieldset>
              <label>
                {" "}
                Address <span className="req-red">*</span> :
              </label>
              <input
                type="text"
                name="address"
                placeholder="Enter Your Address Number"
                value={state?.address}
                onChange={(e) => handleChange(e)}
                required
              />
            </fieldset>

            <fieldset>
              <label>
                {" "}
                City <span className="req-red">*</span> :
              </label>
              <input
                type="text"
                name="city"
                placeholder="Enter Your City Name"
                value={state?.city}
                onChange={(e) => handleChange(e)}
                required
              />
            </fieldset>

            <fieldset>
              <label>
                {" "}
                State <span className="req-red">*</span> :
              </label>
              <input
                type="text"
                name="state"
                placeholder="Enter Your State Name"
                value={state?.state}
                onChange={(e) => handleChange(e)}
                required
              />
            </fieldset>

            <fieldset>
              <label> Blood Group:</label>
              <input
                type="text"
                name="bloodGroup"
                placeholder="Enter Your Blood Group"
                value={state?.bloodGroup}
                onChange={(e) => handleChange(e)}
              />
            </fieldset>

            <fieldset>
              <label> Referred By:</label>
              <input
                type="text"
                name="referredBy"
                placeholder="Referred By"
                value={state?.referredBy == null ? "" : state?.referredBy}
                onChange={(e) => handleChange(e)}
                disabled={reference}
              />
            </fieldset>

            <fieldset>
              <label>
                {" "}
                Preferred Language <span className="req-red">*</span> :
              </label>
              <select
                value={state?.preferredLanguage}
                name="preferredLanguage"
                onChange={(e) => handleChange(e)}
                required
              >
                <option value="Gujarati"> Gujarati </option>
                <option value="Hindi"> Hindi </option>
                <option value="English"> English </option>
              </select>
            </fieldset>

            <fieldset>
              <label>
                {" "}
                Location <span className="req-red">*</span> :
              </label>
              <input
                type="text"
                name="location"
                placeholder="Enter Your Location"
                value={state?.location}
                onChange={(e) => handleChange(e)}
              />
            </fieldset>

            <button className="next_button"> Next </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default React.memo(Details);
