import React, { useEffect, useState } from 'react'
import { mainGetServices, mainPostServices, PARTS, THERAPY } from '../shared/services'
import Loader from './Loader'
import { toast } from 'react-toastify'

function EditParts({ close, caseInfo, getCaseDetails }) {

  const [formData, setFormData] = useState([])
  const [loader, setLoader] = useState(false)
  const [partList, setPartList] = useState([])
  const [showPopup, setShowPopup] = useState(false)
  const [popupData, setPopupData] = useState([])

  const gender = caseInfo?.customer?.gender;

  useEffect(() => {
    getPartList()
    // eslint-disable-next-line
  }, [])


  const getPartList = async () => {
    setLoader(true)
    try {
      const { response_data, response_type } = await mainGetServices(PARTS);
      if (response_type) {
        const filteredArray = response_data[gender].filter(b => {
          return !caseInfo.case_active_parts.some(a => a.part_id === b.id)
        });
        setPartList(filteredArray)
        setLoader(false)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const hadleSubmit = async (e) => {
    if (formData.length > 0) {
      setLoader(true)
      let array = []
      formData.map((item, i) => {
        return array.push({ part: item.id, session: item.sessions.id })
      })

      let data = {
        type: "add",
        part_session: array
      }

      try {
        const { response_type } = await mainPostServices(`${THERAPY}/${window.location.pathname.split('/')[2]}/update_part`, data);
        if (response_type === "success") {
          toast.success(response_type);
          getCaseDetails()
          setLoader(false)
          close(false)
        }
        else {
          setLoader(false)
        }
      } catch (error) {
        console.log(error);
      }
    }
    else {
      alert("Please Select First")
    }
  }

  const hadleClosePopup = () => {
    setShowPopup(false)
    setPopupData([])
  }

  const handlePopup = (item) => {
    setShowPopup(true)
    setPopupData(item)
  }

  const handleClose = () => {
    setShowPopup(false)
  }


  const handleSesssion = (item) => {
    let obj = []
    if (formData.length) {
      const partIndex = formData.findIndex(_ => _.id === item.part_id)
      if (partIndex >= 0) {
        formData[partIndex].sessions = item
        obj = formData
      }
      else {
        obj = formData.concat([{ ...popupData, sessions: item }])
      }
    }
    else {
      obj = [{ ...popupData, sessions: item }]
    }
    setFormData(obj)
  }

  const handleRemove = (item) => {
    const filterData = formData.filter((_) => _.id !== item.id)
    setFormData(filterData)
    hadleClosePopup()
  }

  return (
    <>
      {console.log("formData-------------", formData)}
      <div className='popup editpart'>
        <div className='popup_title'>
          <h2> Add Parts </h2>
        </div>
        <div className='popup_content '>
          <div className='appointment_wrp'>

            <div className='parts'>
              <div className='form_section'>
                {loader && <Loader />}
                <div className='appoint_form' >
                  <div className='appoint_form_wrp'>
                    {partList?.map((item, i) => {
                      return (
                        <div className='appoint_form_title_panel' key={i} onClick={() => handlePopup(item)}>
                          <input name={`female_${item.name}`} type="checkbox" id={`female_${item.id}`} value={item.per_session_price} readOnly checked={formData?.find(ele => ele.id === item.id) || false} />
                          <label htmlFor={`female_${item.id}`} className='parts'>
                            <div className='parts_left'>
                              <div>
                                <img src={item.icon} alt="part_icon" />
                              </div>
                              <span> {item.name}</span>
                            </div>
                            <ul className='parts_right'>
                              <li className='cost'> View </li>
                            </ul>
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className='parts_table_btn'>
                <button className='pay_btn' style={{ marginBottom: "10px", marginTop: "0" }} type='button' onClick={hadleSubmit}> Add Part </button>
                <button className='close_btn' style={{ marginBottom: "10px", marginTop: "0" }} type='button' onClick={() => close(false)}> Cancel </button>
              </div>


              {showPopup &&
                <div className={`partsmodal ${showPopup ? 'active' : ''}`}>
                  <div className='partsmodal_body'>
                    <div className='partsmodal_header'>
                      <h2> Choose Your Plan</h2>
                      <span onClick={() => handleClose()}>
                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.50002 5.15625L10.9198 0.736459C11.3265 0.329724 11.986 0.329724 12.3927 0.736458C12.7995 1.14319 12.7995 1.80264 12.3927 2.20937L7.97293 6.62917L12.3927 11.049C12.7995 11.4557 12.7995 12.1151 12.3927 12.5219C11.986 12.9286 11.3265 12.9286 10.9198 12.5219L6.50002 8.10208L2.08022 12.5219C1.67349 12.9286 1.01404 12.9286 0.607308 12.5219C0.200573 12.1151 0.200573 11.4557 0.607308 11.049L5.0271 6.62917L0.607308 2.20937C0.200573 1.80264 0.200573 1.14319 0.607308 0.736458C1.01404 0.329724 1.67349 0.329724 2.08022 0.736458L6.50002 5.15625Z" fill="#666666" />
                        </svg>
                      </span>
                    </div>
                    <div className='partsmodal_wrp'>
                      {popupData.sessions.map((item, i) => {
                        return (
                          <div className='partsmodal_wrp_item' id={item.id} key={i}>
                            <input type="radio" name={`${popupData.name}`} id={`session_${item.id}`} value={item.price} onChange={() => handleSesssion(item)} defaultChecked={formData?.find(ele => ele?.id === item?.part_id && ele?.sessions?.id === item.id) || false} />
                            <label htmlFor={`session_${item.id}`} className='partsmodal_wrp_item_content'>
                              <div>
                                <h3> {item.session}({item.visit_type})</h3>
                                <p> Rs.{item.price}  </p>
                              </div>
                            </label>
                          </div>
                        )
                      })}

                    </div>

                    <div className='parts_table_btn'>
                      <button className='pay_btn' style={{ marginBottom: "10px", marginTop: "0" }} type='button' onClick={hadleClosePopup}> Select </button>
                      <button className='close_btn' style={{ marginBottom: "10px", marginTop: "0" }} type='button' onClick={() => handleRemove(popupData)}> Remove </button>
                    </div>
                  </div>
                </div>
              }

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(EditParts) 