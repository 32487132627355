import React, { useEffect, useState } from 'react'
import { mainGetServices, PARTS } from '../shared/services'
import Loader from './Loader'

function Parts({ ds, state }) {

  const [partList, setPartList] = useState([])
  const [gender, setGender] = useState('female')
  const [showPopup, setShowPopup] = useState(false)
  const [popupData, setPopupData] = useState([])


  useEffect(() => {
    getPartList()
    // eslint-disable-next-line
  }, [])


  const getPartList = async () => {
    ds({ loader: true })
    try {
      const { response_data, response_type } = await mainGetServices(PARTS);
      if (response_type) {
        setPartList(response_data)
        ds({ loader: false })
      }
    } catch (error) {
      console.log(error);
    }
  }

  const hadleSubmit = (e) => {
    if (state.formData.length > 0) {
      ds({ step: 2 })
    }
    else {
      alert("Please Select First")
    }
  }

  const hadleClosePopup = () => {
    setShowPopup(false)
    setPopupData([])
  }

  const removeSelected = (item) => {
    let formData;
    formData = state.formData.filter((_) => _.sessions.id !== item.id)
    ds({ formData: formData })
  }

  const handlePopup = (item) => {
    setShowPopup(true)
    setPopupData(item)
  }

  const handleClose = () => {
    setShowPopup(false)
  }


  const handleSesssion = (item) => {
    let formData;
    const getGenderWise = state.formData.filter(_ => _.gender === gender)
    if (getGenderWise.length) {
      const partIndex = state.formData.findIndex(_ => _.gender === gender && _.id === item.part_id)
      if (partIndex >= 0) {
        state.formData[partIndex].sessions = item
        formData = state.formData
      }
      else {
        formData = state.formData.concat([{ ...popupData, sessions: item }])
      }
    }
    else {
      formData = [{ ...popupData, sessions: item }]
    }
    // let newFormData = { ...popupData, sessions: item }
    // const newarr = state.formData.some(_ => _.gender === gender) ? [...state.formData, newFormData] : [newFormData]
    ds({ formData: formData })
  }

  const handleChange = (name, value) => {
    ds({ [name]: value ? 'Clinic' : 'Home' })
  }


  return (
    <>
      {console.log("formData", state)}
      <div className='parts'>

        <div className='main_banner'>
          <div className='logo'>
            <img src='/images/logo.svg' alt='logo' />
          </div>
          <div className='tab_section'>
            <ul>
              <li className={`${gender === 'female' ? 'active' : ''}`} onClick={() => setGender('female')}>
                <div className='tab_section_icon'>
                  <img src='/images/female.svg' alt='fenale_icon' />
                </div>
                <span> Female</span>
              </li>
              <li className={`${gender === 'male' ? 'active' : ''}`} onClick={() => setGender('male')}>
                <div className='tab_section_icon'>
                  <img src='/images/male.svg' alt='male_icon' />
                </div>
                <span> male </span>
              </li>
            </ul>
          </div>
          <div className='visittype'>
            Home
            <div className="toggle">
              <input type="checkbox" id='visitype' name='visitType' checked={state.visitType === 'Clinic'} onChange={(e) => handleChange('visitType', e.target.checked)} />
              <label htmlFor='visitype' ></label>
            </div>
            Clinic
          </div>
        </div>

        <div className='form_section'>
          {state.loader && <Loader />}
          <div className='appoint_form' >
            {/* <h2 className='appoint_form_title'> Select Part</h2> */}

            <div className='appoint_form_wrp'>

              {gender === 'female' ?
                partList?.female?.map((item, i) => {
                  return (
                    <div className='appoint_form_title_panel' key={i} onClick={() => handlePopup(item)}>
                      <input name={`female_${item.name}`} type="checkbox" id={`female_${item.id}`} value={item.per_session_price} readOnly checked={state?.formData?.find(ele => ele.gender === 'female' && ele.id === item.id) || false} />
                      <label htmlFor={`female_${item.id}`} className='parts'>
                        <div className='parts_left'>
                          <div>
                            <img src={item.icon} alt="part_icon" />
                          </div>
                          <span> {item.name}</span>
                        </div>
                        <ul className='parts_right'>
                          <li className='cost'> View </li>
                        </ul>
                      </label>
                    </div>
                  )
                })
                :
                partList?.male?.map((item, i) => {
                  return (
                    <div className='appoint_form_title_panel' key={i} onClick={() => handlePopup(item)}>
                      <input name={`male_${item.name}`} type="checkbox" id={`male_${item.id}`} value={item.per_session_price} readOnly checked={state?.formData?.find(ele => ele.gender === 'male' && ele.id === item.id) || false} />
                      <label htmlFor={`male_${item.id}`} className='parts'>
                        <div className='parts_left'>
                          <div>
                            <img src={item.icon} alt="part_icon" />
                          </div>
                          <span> {item.name}</span>
                        </div>
                        <ul className='parts_right'>
                          <li className='cost'> View </li>
                        </ul>
                      </label>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
        <button className='next_button' style={{ marginBottom: "10px", marginTop: "0" }} type='button' onClick={hadleSubmit}> Submit </button>

        {showPopup &&
          <div className={`partsmodal ${showPopup ? 'active' : ''}`}>
            <div className='partsmodal_body'>
              <div className='partsmodal_header'>
                <h2> Choose Your Plan</h2>
                <span onClick={() => handleClose()}>
                  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.50002 5.15625L10.9198 0.736459C11.3265 0.329724 11.986 0.329724 12.3927 0.736458C12.7995 1.14319 12.7995 1.80264 12.3927 2.20937L7.97293 6.62917L12.3927 11.049C12.7995 11.4557 12.7995 12.1151 12.3927 12.5219C11.986 12.9286 11.3265 12.9286 10.9198 12.5219L6.50002 8.10208L2.08022 12.5219C1.67349 12.9286 1.01404 12.9286 0.607308 12.5219C0.200573 12.1151 0.200573 11.4557 0.607308 11.049L5.0271 6.62917L0.607308 2.20937C0.200573 1.80264 0.200573 1.14319 0.607308 0.736458C1.01404 0.329724 1.67349 0.329724 2.08022 0.736458L6.50002 5.15625Z" fill="#666666" />
                  </svg>
                </span>
              </div>
              <div className='partsmodal_wrp'>
                {popupData.sessions.map((item, i) => {
                  return (
                    <div className='partsmodal_wrp_item' id={item.id} key={i}>
                      <input type="radio" name={`${popupData.name}`} id={`session_${item.id}`} value={item.price} onChange={() => handleSesssion(item)} checked={state?.formData?.find(ele => ele?.id === item?.part_id && ele?.sessions?.id === item.id) || false} />
                      <label htmlFor={`session_${item.id}`} className='partsmodal_wrp_item_content'>
                        <div>
                          <h3> {item.session}({item.visit_type})</h3>
                          <p> Rs.{item.price}  </p>
                        </div>
                      </label>
                      {state?.formData?.find(ele => ele?.id === item?.part_id && ele?.sessions?.id === item.id) && <span onClick={() => removeSelected(item)}> X </span>}
                    </div>
                  )
                })}

              </div>
              <button className='next_button' type='button' onClick={hadleClosePopup}> Select </button>
            </div>
          </div>
        }

      </div>
    </>
  )
}

export default React.memo(Parts) 