import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function BackButton({ url }) {
  const navigate = useNavigate();
  const backButtonRef = useRef(null);

  useEffect(() => {}, []);

  if (window.history && window.history.pushState) {
    window.addEventListener("popstate", () => {
      if (url) {
        navigate(url);
      }
    });
  }

  return (
    <button className="back" ref={backButtonRef} onClick={() => navigate(url)}>
      <svg width="37px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.807 23l-6.67-6.67a.466.466 0 0 1 0-.66L18.807 9"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke="#39464e"
        ></path>
      </svg>
    </button>
  );
}

export default BackButton;
